<template>
  <!-- <div class="app">
    <MainNavbar /> -->
    <div class="content">
      <GalleryHeader />
    </div>
    <!-- <MainFooter />
  </div> -->
</template>

<script setup>
  import GalleryHeader from '@/components/GalleryHeader.vue'
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
// @ is an alias to /src
// import MainNavbar from '@/components/MainNavbar.vue'
// import MainFooter from '@/components/MainFooter.vue'

// export default {
//   name: 'GalleryPage',
//   components: {
//     // MainNavbar,
//     GalleryHeader
//     // MainFooter
//   }
// }
</script>