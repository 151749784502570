<template>
    <section>
        <div class="section-left">
            <div class="sl-inner">
                <h2>Tradisi. Histori. Memori. Sensori. Mind Sense</h2>
                <p class="p-1">Anda mungkin telah mendengar pepatah “Kita adalah apa yang kita makan”. Makan mencerminkan pembuatan diri, aspek etis, politik, sosial, artistik, dan identitas.</p>
                <p class="p-2">Di Food Traveller Bandung, Anda akan menemukan berbagai tempat makanan yang tidak hanya menyediakan menu makanan dan minuman. Tetapi juga didasari oleh tradisi, histori, memori, sensori, serta mind sense. Tempat-tempat makan yang akan menawarkan Anda tidak hanya sajiannya tetapi juga mendalami latar belakang makanan tersebut sampai pemilihan bumbu dan bahan yang digunakan dalam pembuatan masakan tersebut.</p>
                <h3>Eat well. Travel often</h3>
            </div>
        </div>
        <div class="section-right">
            <img src="../../assets/images/picture/banana-woman.png" alt="food traveller" />
        </div>
    </section>
</template>

<script>
    export default {
        name: 'HomeTradisi'
    }
</script>

<style scoped>
    section{
        display: flex;
        width: 100%;
        min-height: 50rem;
        background: #75C6EF;
        color: #ffffff;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        transition: 0.2s;
    }
    .section-left{
        width: 50%;
        padding-top: 5.9375rem;
        padding-right: 7rem;
        padding-bottom: 5.9375rem;
        transition: 0.2s;
    }
    .section-left h2{
        font-family: poppins;
        font-size: 2.625rem;
        font-weight: 700;
        margin: 0;
        transition: 0.2s;
    }
    .p-1{
        margin-top: 1.6875rem;
        font-size: 1.5rem;
    }
    .p-2{
        margin-top: 1.6875rem;
        font-size: 1.5rem;
    }
    .section-left h3{
        font-family: poppins;
        font-size: 1.375rem;
        font-weight: 700;
        margin: 0;
        margin-top: 1.6875rem;
    }
    .section-right{
        position: relative;
        width: 50%;
        transition: 0.2s;
    }
    .section-right img{
        position: absolute;
        top: 5.9375rem;
        right: 0;
        width: 100%;
        max-width: 45rem;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding-left: 5%;
            padding-right: 5%;
        }
    }
    @media only screen and (max-width: 1050px){
        .section-left h2{
            font-size: 2rem;
        }
    }
    @media only screen and (max-width: 795px){
        .section-left{
            width: 100%;
            padding-top: 2rem;
            padding-right: 0;
            text-align: left;
        }
        .section-right{
            width: 0;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding-left: 2.5%;
            padding-right: 2.5%;
            min-height: unset;
        }
        .section-left{
            padding-bottom: 2rem;
        }
        .p-1{
            font-size: 1rem;
        }
        .p-2{
            font-size: 1rem;
        }
    }
</style>