<template>
    <section>
        <div class="section-bg"></div>
        <div class="section-header">
            <h2>Travel Directory</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="section-content">
            <div class="sc-inner-x">
                <div class="section-tab">
                    <div class="tab-head">
                        <div class="tab-button tb-active" id="button-hotel" @click="selectTabTravel('hotel', 'button-hotel')">Hotel</div>
                        <div class="tab-button" id="button-wisata" @click="selectTabTravel('wisata', 'button-wisata')">Wisata Bandung</div>
                        <div class="tab-button" id="button-pijat" @click="selectTabTravel('pijat', 'button-pijat')">Pijat Keluarga</div>
                        <div class="tab-button" id="button-travel" @click="selectTabTravel('travel', 'button-travel')">Travel</div>
                    </div>
                    <div class="tab-body">
                        <div class="tab-content tc-first" id="hotel">
                            <div class="sc-inner">
                                <div class="sci-left">
                                    <div class="sci-left-top">
                                        <img src="../../assets/images/picture/imgtt1.png" alt="food traveller">
                                        <div class="sci-caption sci-caption-left">
                                            <h3>Novotel</h3>
                                        </div>
                                    </div>
                                    <div class="sci-left-bottom">
                                        <img src="../../assets/images/picture/imgtt2.png" alt="food traveller">
                                        <div class="sci-caption sci-caption-left">
                                            <h3>The Palace</h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="sci-right">
                                    <img src="../../assets/images/picture/imgtt3.png" alt="food traveller">
                                    <div class="sci-caption sci-caption-right">
                                        <h3>Savoy Homan Bandung</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="sc-inner">
                                <div class="sci-right">
                                    <img src="../../assets/images/picture/imgtt4.png" alt="food traveller">
                                    <div class="sci-caption sci-caption-right">
                                        <h3>Hotel Horizon Bandung</h3>
                                    </div>
                                </div>
                                <div class="sci-left">
                                    <div class="sci-left-top">
                                        <img src="../../assets/images/picture/imgtt5.png" alt="food traveller">
                                        <div class="sci-caption sci-caption-left">
                                            <h3>BananaIn</h3>
                                        </div>
                                    </div>
                                    <div class="sci-left-bottom">
                                        <img src="../../assets/images/picture/imgtt6.png" alt="food traveller">
                                        <div class="sci-caption sci-caption-left">
                                            <h3>The Palace</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-content" id="wisata">
                            <div class="sc-inner">
                                <div class="sci-left">
                                    <div class="sci-left-top">
                                        <img src="../../assets/images/picture/imgtt2.png" alt="food traveller">
                                        <div class="sci-caption sci-caption-left">
                                            <h3>The Palace</h3>
                                        </div>
                                    </div>
                                    <div class="sci-left-bottom">
                                        <img src="../../assets/images/picture/imgtt1.png" alt="food traveller">
                                        <div class="sci-caption sci-caption-left">
                                            <h3>Novotel</h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="sci-right">
                                    <img src="../../assets/images/picture/imgtt4.png" alt="food traveller">
                                    <div class="sci-caption sci-caption-right">
                                        <h3>Hotel Horizon Bandung</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="sc-inner">
                                <div class="sci-right">
                                    <img src="../../assets/images/picture/imgtt3.png" alt="food traveller">
                                    <div class="sci-caption sci-caption-right">
                                        <h3>Savoy Homan Bandung</h3>
                                    </div>
                                </div>
                                <div class="sci-left">
                                    <div class="sci-left-top">
                                        <img src="../../assets/images/picture/imgtt5.png" alt="food traveller">
                                        <div class="sci-caption sci-caption-left">
                                            <h3>BananaIn</h3>
                                        </div>
                                    </div>
                                    <div class="sci-left-bottom">
                                        <img src="../../assets/images/picture/imgtt6.png" alt="food traveller">
                                        <div class="sci-caption sci-caption-left">
                                            <h3>The Palace</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-content" id="pijat">
                            <div class="sc-inner">
                                <div class="sci-left">
                                    <div class="sci-left-top">
                                        <img src="../../assets/images/picture/imgtt1.png" alt="food traveller">
                                        <div class="sci-caption sci-caption-left">
                                            <h3>Novotel</h3>
                                        </div>
                                    </div>
                                    <div class="sci-left-bottom">
                                        <img src="../../assets/images/picture/imgtt2.png" alt="food traveller">
                                        <div class="sci-caption sci-caption-left">
                                            <h3>The Palace</h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="sci-right">
                                    <img src="../../assets/images/picture/imgtt3.png" alt="food traveller">
                                    <div class="sci-caption sci-caption-right">
                                        <h3>Savoy Homan Bandung</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="sc-inner">
                                <div class="sci-right">
                                    <img src="../../assets/images/picture/imgtt4.png" alt="food traveller">
                                    <div class="sci-caption sci-caption-right">
                                        <h3>Hotel Horizon Bandung</h3>
                                    </div>
                                </div>
                                <div class="sci-left">
                                    <div class="sci-left-top">
                                        <img src="../../assets/images/picture/imgtt5.png" alt="food traveller">
                                        <div class="sci-caption sci-caption-left">
                                            <h3>BananaIn</h3>
                                        </div>
                                    </div>
                                    <div class="sci-left-bottom">
                                        <img src="../../assets/images/picture/imgtt6.png" alt="food traveller">
                                        <div class="sci-caption sci-caption-left">
                                            <h3>The Palace</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-content" id="travel">
                            <div class="sc-inner">
                                <div class="sci-left">
                                    <div class="sci-left-top">
                                        <img src="../../assets/images/picture/imgtt2.png" alt="food traveller">
                                        <div class="sci-caption sci-caption-left">
                                            <h3>The Palace</h3>
                                        </div>
                                    </div>
                                    <div class="sci-left-bottom">
                                        <img src="../../assets/images/picture/imgtt1.png" alt="food traveller">
                                        <div class="sci-caption sci-caption-left">
                                            <h3>Novotel</h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="sci-right">
                                    <img src="../../assets/images/picture/imgtt4.png" alt="food traveller">
                                    <div class="sci-caption sci-caption-right">
                                        <h3>Hotel Horizon Bandung</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="sc-inner">
                                <div class="sci-right">
                                    <img src="../../assets/images/picture/imgtt3.png" alt="food traveller">
                                    <div class="sci-caption sci-caption-right">
                                        <h3>Savoy Homan Bandung</h3>
                                    </div>
                                </div>
                                <div class="sci-left">
                                    <div class="sci-left-top">
                                        <img src="../../assets/images/picture/imgtt5.png" alt="food traveller">
                                        <div class="sci-caption sci-caption-left">
                                            <h3>BananaIn</h3>
                                        </div>
                                    </div>
                                    <div class="sci-left-bottom">
                                        <img src="../../assets/images/picture/imgtt6.png" alt="food traveller">
                                        <div class="sci-caption sci-caption-left">
                                            <h3>The Palace</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-footer">
                        <router-link to="/kuliner">Load more hotels</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'TravelTravel',
    methods: {
        selectTabTravel(a, b){
            var tb = document.getElementsByClassName('tab-button');
            var tc = document.getElementsByClassName('tab-content');
            var i;
            for(i = 0; i < tc.length; i++){
                tc[i].style.display = 'none';
                tb[i].classList.remove('tb-active');
            }
            document.getElementById(a).style.display = 'block';
            document.getElementById(b).classList.add('tb-active');
        }
    }
}
</script>

<style scoped>
    section{
        position: relative;
        width: 100%;
        min-height: 15rem;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        color: #1B4674;
    }
    .section-bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 140rem;
        background: #FEF8F0;
        z-index: -1;
    }
    section h2{
        font-family: poppins;
        font-size: 2rem;
        font-weight: 700;
    }
    section p{
        margin-top: 1.6875rem;
        font-size: 1rem;
        font-weight: 400;
        width: 31.9375rem;
    }
    .section-header{
        text-align: left;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
    }
    .section-content{
        width: 100%;
        height: 100%;
        margin-top: 2rem;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
    }
    .sc-inner-x{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        padding-top: 2rem;
    }
    .section-tab{
        width: 100%;
    }
    .tab-head{
        display: flex;
        flex-wrap: nowrap;
    }
    .tab-button{
        cursor: pointer;
        margin-right: 1rem;
        font-family: poppins;
        font-size: 1.25rem;
        font-weight: 700;
        color: #bbbbbb;
        padding-bottom: 1rem;
        user-select: none;
    }
    .tb-active{
        color: #1C4576;
        border-bottom: 5px solid #75C6EF;
    }
    .tab-body{
        width: 100%;
        min-height: 10rem;
    }
    .tab-content{
        display: none;
    }
    .tc-first{
        display: block;
    }
    .tc-inner{
        display: flex;
        flex-wrap: wrap;
        padding-top: 2rem;
    }
    .sc-inner{
        display: flex;
        width: 100%;
        height: 67rem;
        margin-top: 1rem;
    }
    .sc-inner img{
        width: 100%;
        height: 100%;
    }
    .sci-caption{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        color: #ffffff;
        font-family: poppins;
        font-weight: 700;
    }
    .sci-caption-left{
        font-size: 1.75rem;
        padding: 2rem;
    }
    .sci-caption-right{
        font-size: 2.625rem;
        padding: 2.5rem;
    }
    .sci-left{
        width: 45%;
        height: 100%;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .sci-left-top{
        position: relative;
        width: 100%;
        height: 49%;
    }
    .sci-left-bottom{
        position: relative;
        width: 100%;
        height: 49.5%;
        margin-top: 1rem;
    }
    .sci-right{
        position: relative;
        width: 55%;
        height: 100%;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .tab-footer{
        margin-top: 1.6875rem;
        text-align: center;
    }
    .tab-footer a{
        text-decoration: underline;
        color: #575757;
        font-size: 1rem;
        font-weight: 600;
    }
    /*  */
</style>