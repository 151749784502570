<template>
    <section>
        <div class="section-head">
            <div class="sh-inner">
                <h2>Food Traveller Bandung</h2>
                <h1>{{ activeCategory }}</h1>
                <p>Halaman yang memberikan kemudahan akses untuk mendapatkan berita dan artikel terkini mengenai kesehatan, gaya hidup, dan informasi menarik lainnya dan tentunya informasi terbaru mengenai RSIA Melinda.</p>
            </div>
        </div>
        <div class="section-body" v-if="articlesShow">
            <div class="sb-inner">
                <div class="sb-part" v-for="article in articlesShow" :key="article">
                    <router-link :to="'/blog/detail/' + article.slug">
                        <div class="sb-part-inner">
                            <div class="spi-image">
                                <img :src="imgsrc + article.image" alt="food traveller bandung"/>
                            </div>
                            <div class="spi-text">
                                <div class="spi-category">
                                    <span>{{ article.category?.title }}</span>
                                </div>
                                <div class="spi-title">
                                    <h2>{{ article.title }}</h2>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="section-more" v-if="articlesShow.length < articles.length">
                <button @click="useBlogStore().moreAbc();">Load More</button>
            </div>
        </div>
        <!-- <ArticlesBodySkeleton v-else/> -->
    </section>
</template>

<script setup>
    // import ArticlesBodySkeleton from '@/components/skeletons/parts/ArticlesBodySkeleton.vue';
    import { useRoute } from 'vue-router';
    import { computed } from '@vue/reactivity';
    import { useBlogStore } from '@/stores/blog';
    // import { topSearchSelect } from '@/functions/general';
    // import { searchbarInput } from '@/functions/article';
    // const searchBar = computed(() => useBlogStore().searchBar);
    const articles = computed(() => useBlogStore().articlesByCategory);
    const activeCategory = computed(() => useBlogStore().activeCategory);
    const articlesShow = computed(() => useBlogStore().abcShow);
    const imgsrc = process.env.VUE_APP_ROOT_API + 'images/articles/';
    // const categories = computed(() => useBlogStore().categories);
    useBlogStore().setArticlesByCategory(useRoute().params.slug);
</script>

<style scoped>
    section{
        padding: 2.5rem 0 2.5rem 0;
    }
    .section-head{
        position: relative;
        width: 100%;
        padding: 0 10% 0 10%;
    }
    .sh-inner{
        text-align: center;
    }
    .sh-inner h2{
        font-size: 1.1rem;
        color: var(--primary);
        margin: 0;
    }
    .sh-inner h1{
        font-size: 2rem;
        color: var(--navy);
        margin: 1rem 0 1rem 0;
    }
    .sh-inner p{
        margin: 0;
        color: var(--grey);
    }
    .si-box{
        position: absolute;
        left: 10%;
        right: 10%;
        bottom: 0;
        /* width: 100%; */
        transition: 0.2s;
    }
    .si-search{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 75px;
        transition: 0.2s;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.37), 0 6px 20px 0 rgba(0, 0, 0, 0.37);
    }
    .si-search-select{
        position: relative;
        width: 30%;
        height: 100%;
        user-select: none;
        background: var(--white);
        transition: 0.2s;
    }
    .sss-button{
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        padding: 0.5rem;
    }
    .button-cover{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .sss-text{
        width: 90%;
    }
    .sss-text-title{
        font-weight: 700;
        padding: 0 0.5rem 0 0.5rem;
    }
    .sss-text-button{
        padding: 0.5rem;
        font-size: 0.9rem;
    }
    .sss-icon{
        width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sss-icon-inner{
        text-align: center;
        width: 20px;
        height: 20px;
    }
    .sss-icon img{
        max-width: 100%;
        transition: 0.2s;
    }
    .sss-option{
        position: absolute;
        top: 110%;
        left: 0;
        width: 100%;
        background: var(--white);
        display: none;
        -webkit-animation-name: animatesssoption;
        -webkit-animation-duration: 0.4s;
        animation-name: animatesssoption;
        animation-duration: 0.4s;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.37), 0 6px 20px 0 rgba(0, 0, 0, 0.37);
        z-index: 5;
    }
    @-webkit-keyframes animatesssoption {
        from {top:150%; opacity:0}
        to {top:110%; opacity:1}
    }
    @keyframes animatesssoption {
        from {top:150%; opacity:0}
        to {top:110%; opacity:1}
    }
    .float-active{
        display: block;
    }
    .sss-option-button{
        width: 100%;
        padding: 0.5rem 1rem 0.5rem 1rem;
    }
    .sss-option-button:hover{
        background: var(--smoke);
    }
    .si-search-input{
        width: 40%;
        height: 100%;
        display: flex;
        align-items: center;
        border-left: 1px solid var(--softgrey);
        padding: 1rem;
        background: var(--white);
        transition: 0.2s;
    }
    .si-search-input input{
        border: none;
        outline: none;
        width: 100%;
        height: 50px;
        color: var(--grey);
        font-size: 1.2rem;
    }
    .si-search-button{
        width: 30%;
        height: 100%;
        background: var(--primary);
        color: var(--white);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;
        transition: 0.2s;
    }
    .section-body{
        padding: 2.5rem 10% 0 10%;
        transition: 0.2s;
    }
    .sb-inner{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    .sb-part{
        width: 33.33%;
        height: 270px;
        padding: 0.5rem;
        transition: 0.2s;
    }
    .sb-part-inner{
        position: relative;
        width: 100%;
        height: 100%;
        /* padding: 0.5rem; */
        /* border: 1px solid green; */
        border-radius: 5px;
        overflow: hidden;
    }
    .spi-image{
        width: 100%;
        height: 100%;
        /* background: var(--grey); */
        animation: pulse-bg 1s infinite;
    }
    .spi-image img{
        width: 100%;
        /* height: 100%; */
    }
    .spi-text{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100px;
        /* background: #0000006e; */
        background-image: linear-gradient(#0000009c, #000000);
        color: var(--white);
        padding: 0.5rem;
    }
    .spi-category{
        padding: 0.5rem 0 0.5rem 0;
        font-size: 0.75rem;
    }
    .spi-category span{
        padding: 0.5rem 1rem 0.5rem 1rem;
        background: var(--primary);
        color: var(--white);
        border-radius: 5px;
    }
    .spi-title{
        padding: 0.5rem 0 0 0;
    }
    .spi-title h2{
        font-size: 1.1rem;
        margin: 0;
        transition: 0.2s;
    }
    .section-more{
        /* position: absolute;
        left: 0;
        bottom: 0; */
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 1rem 0 1rem 0;
    }
    .section-more button{
        font-family: poppins;
        font-weight: 500;
        font-size: 1rem;
        border: none;
        background: none;
        color: var(--grey);
        text-decoration: underline;
        cursor: pointer;
        user-select: none;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 2.5rem 0 2.5rem 0;
        }
        .section-head{
            padding: 0 5% 0 5%;
        }
        .si-box{
            left: 5%;
            right: 5%;
        }
        .section-body{
            padding: 2.5rem 4.5% 0 4.5%;
        }
    }
    @media only screen and (max-width: 1000px){
        .section-head{
            padding: 0 5% 0 5%;
        }
        /* .section-body{
            padding: 2.5rem 5% 0 5%;
        } */
        /* .si-box{
            width: 100%;
        } */
        .si-search{
            height: auto;
            box-shadow: unset;
        }
        .si-search-select{
            width: 50%;
            height: 75px;
            border-top: 1px solid var(--softgrey);
            border-left: 1px solid var(--softgrey);
            border-bottom: 1px solid var(--softgrey);
        }
        .si-search-input{
            width: 50%;
            height: 75px;
            border-top: 1px solid var(--softgrey);
            border-right: 1px solid var(--softgrey);
            border-bottom: 1px solid var(--softgrey);
        }
        .si-search-button{
            width: 100%;
            height: 75px;
            margin-top: 1rem;
        }
        .sb-part{
            width: 50%;
        }
        .spi-title h2{
            font-size: 1rem;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 2.5rem 0 2.5rem 0;
        }
        .section-body{
            padding: 2.5rem 1% 0 1%;
        }
        .section-head{
            padding: 0 2.5% 0 2.5%;
        }
        .si-box{
            left: 2.5%;
            right: 2.5%;
        }
        .si-search-select{
            width: 100%;
            border-right: 1px solid var(--softgrey);
        }
        .si-search-input{
            width: 100%;
            margin-top: 1rem;
            border: 1px solid var(--softgrey);
        }
        .si-search-button{
            width: 100%;
        }
        .spi-title h2{
            font-size: 0.9rem;
        }
    }
    @media only screen and (max-width: 600px){
        .sb-part{
            width: 100%;
        }
    }
    /*  */
</style>