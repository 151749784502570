<template>
  <!-- <div class="app">
    <MainNavbar /> -->
    <div class="content">
      <BlogHeader />
      <ArticlesComponent />
      <!-- <BlogFood /> -->
      <!-- <BlogTravel /> -->
    </div>
    <!-- <MainFooter />
  </div> -->
</template>

<script setup>
    import BlogHeader from '@/components/blog/BlogHeader.vue';
    import ArticlesComponent from '@/components/blog/ArticlesComponent.vue';
    // import BlogFood from '@/components/blog/BlogFood.vue';
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
</script>