<template>
    <section>
        <div class="section-header">
            <h2>We Passionate To Share Delicious Food and Travel Info</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="cov-strip">
            <div class="strip"></div>
        </div>
        <div class="section-content">
            <div class="sc-inner">
                <div class="sci-part">
                    <div class="sci-icon">
                        <img src="../../assets/images/icons/imgap1.png" alt="food traveller" />
                    </div>
                    <div class="sci-text">
                        <span>We Provide Food & Travel Info</span>
                    </div>
                </div>
                <div class="sci-part">
                    <div class="sci-icon">
                        <img src="../../assets/images/icons/imgap2.png" alt="food traveller" />
                    </div>
                    <div class="sci-text">
                        <span>Update on Directory</span>
                    </div>
                </div>
                <div class="sci-part">
                    <div class="sci-icon">
                        <img src="../../assets/images/icons/imgap3.png" alt="food traveller" />
                    </div>
                    <div class="sci-text">
                        <span>Team & Loyal</span>
                    </div>
                </div>
                <div class="sci-part">
                    <div class="sci-icon">
                        <img src="../../assets/images/icons/imgap4.png" alt="food traveller" />
                    </div>
                    <div class="sci-text">
                        <span>Community</span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'AboutPassion',
    methods: {
        // 
    }
}
</script>

<style scoped>
    section{
        width: 100%;
        min-height: 15rem;
        padding-top: 2.5rem;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        padding-bottom: 2.5rem;
        color: #1B4674;
        background: #FEF8F0;
        transition: 0.2s;
    }
    section h2{
        font-family: poppins;
        font-size: 2rem;
        font-weight: 700;
        transition: 0.2s;
        margin: 0;
    }
    section p{
        margin-top: 1.6875rem;
        font-size: 1rem;
    }
    .cov-strip{
        display: flex;
        justify-content: center;
        padding-top: 2rem;
    }
    .strip{
        width: 5%;
        height: 0.5rem;
        background: #75C6EF;
    }
    .section-header{
        text-align: center;
        padding-left: 30%;
        padding-right: 30%;
        transition: 0.2s;
    }
    .section-content{
        width: 100%;
        height: 100%;
        margin-top: 5rem;
    }
    .sc-inner{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
    }
    .sci-part{
        width: 25%;
        height: auto;
        text-align: center;
        padding-left: 1%;
        padding-right: 1%;
        transition: 0.2s;
    }
    .sci-icon{
        height: 75%;
    }
    .sci-icon img{
        height: 100%;
        margin-bottom: 1rem;
    }
    .sci-text{
        font-weight: 1.125rem;
        font-weight: 600;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding-left: 5%;
            padding-right: 5%;
        }
        .section-header{
            padding-left: 0;
            padding-right: 0;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding-left: 2.5%;
            padding-right: 2.5%;
            padding-bottom: 2rem;
        }
        section h2{
            font-size: 1.5rem;
        }
        .section-header{
            text-align: left;
        }
        .cov-strip{
            justify-content: left;
        }
        .strip{
            width: 15%;
        }
        .section-content{
            margin-top: 2.6rem;
        }
        .sci-part{
            width: 50%;
            padding-top: 1rem;
            padding-bottom: 1rem;
            /* border: 1px solid green; */
        }
    }
</style>