<template>
    <div class="content">
        <KamusPanganCategoryComponent />
    </div>
</template>

<script setup>
    import KamusPanganCategoryComponent from '@/components/kamus-rempah/KamusPanganCategoryComponent.vue';
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
</script>