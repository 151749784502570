<template>
    <div class="content">
        <BlogCategory />
    </div>
</template>

<script setup>
    import BlogCategory from '@/components/blog/BlogCategory.vue';
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
</script>