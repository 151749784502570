<template>
    <section>
        <div class="section-bg"></div>
        <div class="section-header">
            <h2>Resep Food Traveller</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="section-content">
            <div class="sc-inner">
                <div class="section-tab">
                    <div class="ths-button float-button-mobile" @click="thsOption">
                        <div class="ths-button-text float-button-mobile" id="hbtbt">Resep Makanan</div>
                        <div class="ths-button-icon float-button-mobile">
                            <img class="tbi-img float-button-mobile" id="tbi-img" src="../../assets/images/icons/arrow-1.png" alt="p3k melinda">
                        </div>
                    </div>
                    <div class="tab-head mobile-noflex float-mobile" id="tab-head">
                        <div class="cov-tab-button" @click="ctbClick('Resep Makanan'); selectTabResep('makanan', 'button-makanan');">
                            <div class="tab-button tb-active" id="button-makanan">Resep Makanan</div>
                        </div>
                        <div class="cov-tab-button" @click="ctbClick('Resep Minuman'); selectTabResep('minuman', 'button-minuman');">
                            <div class="tab-button" id="button-minuman">Resep Minuman</div>
                        </div>
                        <div class="cov-tab-button" @click="ctbClick('Resep Makanan Khas'); selectTabResep('khas', 'button-khas');">
                            <div class="tab-button" id="button-khas">Resep Makanan Khas</div>
                        </div>
                        <div class="cov-tab-button" @click="ctbClick('Resep Cemilan'); selectTabResep('cemilan', 'button-cemilan');">
                            <div class="tab-button" id="button-cemilan">Resep Cemilan</div>
                        </div>
                    </div>
                    <div class="tab-body">
                        <div class="tab-content tc-first" id="makanan">
                            <RempahCardSkeleton v-if="!makanan"/>
                            <div class="tc-inner" v-else>
                                <div class="tci-part" v-for="(resep) in makanan" :key="resep.id">
                                    <div class="tcp-img">
                                        <img v-bind:src="imgsrc + resep.picture" alt="food traveller" />
                                        <div class="sci-gradient"></div>
                                    </div>
                                    <div class="tci-part-caption">
                                        <div class="tci-part-label">{{ resep.category_name }}</div>
                                        <div class="tci-part-captext">
                                            <router-link :to="'/resep/detail/' + resep.id">
                                                <h3>{{ resep.title }}</h3>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-content" id="minuman">
                            <RempahCardSkeleton v-if="!minuman"/>
                            <div class="tc-inner" v-else>
                                <div class="tci-part" v-for="(resep) in minuman" :key="resep.id">
                                    <div class="tcp-img">
                                        <img v-bind:src="imgsrc + resep.picture" alt="food traveller" />
                                        <div class="sci-gradient"></div>
                                    </div>
                                    <div class="tci-part-caption">
                                        <div class="tci-part-label">{{ resep.category_name }}</div>
                                        <div class="tci-part-captext">
                                            <router-link :to="'/resep/detail/' + resep.id">
                                                <h3>{{ resep.title }}</h3>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-content" id="khas">
                            <RempahCardSkeleton v-if="!makananKhas"/>
                            <div class="tc-inner" v-else>
                                <div class="tci-part" v-for="(resep) in makananKhas" :key="resep.id">
                                    <div class="tcp-img">
                                        <img v-bind:src="imgsrc + resep.picture" alt="food traveller" />
                                        <div class="sci-gradient"></div>
                                    </div>
                                    <div class="tci-part-caption">
                                        <div class="tci-part-label">{{ resep.category_name }}</div>
                                        <div class="tci-part-captext">
                                            <router-link :to="'/resep/detail/' + resep.id">
                                                <h3>{{ resep.title }}</h3>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-content" id="cemilan">
                            <RempahCardSkeleton v-if="!cemilan"/>
                            <div class="tc-inner" v-else>
                                <div class="tci-part" v-for="(resep) in cemilan" :key="resep.id">
                                    <div class="tcp-img">
                                        <img v-bind:src="imgsrc + resep.picture" alt="food traveller" />
                                        <div class="sci-gradient"></div>
                                    </div>
                                    <div class="tci-part-caption">
                                        <div class="tci-part-label">{{ resep.category_name }}</div>
                                        <div class="tci-part-captext">
                                            <router-link :to="'/resep/detail/' + resep.id">
                                                <h3>{{ resep.title }}</h3>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-footer">
                        <router-link to="/resep">Load more resep</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { computed } from "@vue/runtime-core";
    import { useRecipeStore } from "../../stores/recipe";
    import RempahCardSkeleton from "../skeletons/RempahCardSkeleton.vue";
    const makanan = computed(() => useRecipeStore().resepMakanan);
    const minuman = computed(() => useRecipeStore().resepMinuman);
    const makananKhas = computed(() => useRecipeStore().resepMakananKhas);
    const cemilan = computed(() => useRecipeStore().resepCemilan);
    useRecipeStore().setRm(2, 9); // (article_categories.id, limit)
    useRecipeStore().setRmi(1, 9);
    useRecipeStore().setRmkhas(3, 9);
    useRecipeStore().setRcem(4, 9);
    function selectTabResep(a, b){
        var tb = document.getElementsByClassName('tab-button');
        var tc = document.getElementsByClassName('tab-content');
        var i;
        for(i = 0; i < tc.length; i++){
            tc[i].style.display = 'none';
            tb[i].classList.remove('tb-active');
        }
        document.getElementById(a).style.display = 'block';
        document.getElementById(b).classList.add('tb-active');
    }
    function thsOption(){
        if(document.getElementById('tab-head').style.display == 'flex'){
            document.getElementById('tab-head').style.display = 'none';
            document.getElementById('tbi-img').style.transform = 'rotate(0)';
        }else{
            document.getElementById('tab-head').style.display = 'flex';
            document.getElementById('tbi-img').style.transform = 'rotate(540deg)';
        }
    }
    function ctbClick(a){
        document.getElementById('hbtbt').innerHTML = a;
    }
    const imgsrc = process.env.VUE_APP_ROOT_API + 'images/resep/';
</script>

<style scoped>
    section{
        position: relative;
        width: 100%;
        min-height: 15rem;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        color: #1B4674;
    }
    .section-bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 32rem;
        background: #FEF8F0;
        z-index: -1;
        transition: 0.2s;
    }
    section h2{
        font-family: poppins;
        font-size: 2rem;
        font-weight: 700;
        margin: 0;
    }
    section p{
        margin: 0;
        margin-top: 1.6875rem;
        margin-bottom: 1rem;
        font-size: 1rem;
        font-weight: 400;
        width: 31.9375rem;
        max-width: 100%;
    }
    .section-header{
        text-align: left;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        transition: 0.2s;
    }
    .section-content{
        width: 100%;
        height: 100%;
        margin-top: 1rem;
        padding-left: 11.25rem;
        padding-right: 11.25rem;
        transition: 0.2s;
    }
    .sc-inner{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        padding-top: 1rem;
        transition: 0.2s;
    }
    .section-tab{
        position: relative;
        width: 100%;
        z-index: 10;
    }
    .ths-button{
        display: none;
    }
    .tab-head{
        display: flex;
        flex-wrap: nowrap;
        padding-left: 1rem;
        padding-right: 1rem;
        overflow: auto;
    }
    .tab-head::-webkit-scrollbar{
        display: none;
    }
    .tab-button{
        cursor: pointer;
        margin-right: 1rem;
        font-family: poppins;
        font-size: 1.25rem;
        font-weight: 700;
        color: #bbbbbb;
        padding-bottom: 1rem;
        user-select: none;
        white-space: nowrap;
    }
    .tb-active{
        color: #1C4576;
        border-bottom: 5px solid #75C6EF;
    }
    .tab-body{
        width: 100%;
        min-height: 24rem;
    }
    .tab-content{
        display: none;
    }
    .tc-first{
        display: block;
    }
    .tc-inner{
        display: flex;
        flex-wrap: wrap;
        padding-top: 2rem;
    }
    .tc-inner::-webkit-scrollbar{
        display: none;
    }
    .tci-part{
        position: relative;
        min-width: 15rem;
        width: 33.33%;
        margin-bottom: 2rem;
        padding-left: 1%;
        padding-right: 1%;
    }
    .tcp-img{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .tcp-img img{
        width: 100%;
        min-height: 24rem;
        height: 100%;
        border-radius: 10px;
    }
    .sci-gradient{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 45%;
        background-image: linear-gradient(transparent, #000000);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    /* .tci-part-center{
        margin-left: 5%;
        margin-right: 5%;
    }
    .tci-part img{
        width: 100%;
    } */
    .tci-part-caption{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .tci-part-label{
        position: absolute;
        top: 5%;
        left: 5%;
        background: #75C6EF;
        color: #ffffff;
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        border-radius: 5rem;
    }
    .tci-part-captext{
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        color: #ffffff;
        padding: 2.3125rem;
        padding-top: 0;
    }
    .tci-part-captext a{
        color: #ffffff;
        text-decoration: none;
    }
    .tci-part-captext h3{
        margin: 0;
        font-family: poppins;
        font-size: 1.5rem;
        font-weight: 700;
    }
    .tci-part-captext p{
        margin: 0;
        font-family: poppins;
        font-size: 1rem;
        font-weight: 400;
    }
    .tab-footer{
        margin-top: 1.6875rem;
        text-align: center;
    }
    .tab-footer a{
        text-decoration: underline;
        color: #575757;
        font-size: 1rem;
        font-weight: 600;
    }
    @media only screen and (max-width: 1500px){
        .section-header{
            padding-left: 5%;
            padding-right: 5%;
        }
        .section-content{
            padding-left: 4%;
            padding-right: 4%;
        }
    }
    @media only screen and (max-width: 1250px){
        section{
            padding-top: 2.5rem;
        }
        .tab-body{
            min-height: 19rem;
        }
        .tcp-img img{
            min-height: 19rem;
        }
        .tci-part-captext h3{
            font-size: 1.2rem;
        }
    }
    @media only screen and (max-width: 1050px){
        .tab-body{
            min-height: 17rem;
        }
        .tcp-img img{
            min-height: 17rem;
        }
        .tci-part-captext h3{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    @media only screen and (max-width: 850px){
        .tc-inner{
            overflow: auto;
        }
        .tab-body{
            min-height: 15rem;
        }
        .tcp-img img{
            min-height: 15rem;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding-top: 0.5rem;
            padding-left: 0;
            padding-right: 0;
        }
        .section-bg{
            height: 26rem;
        }
        section h2{
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
        section p{
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
        .section-header{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .section-content{
            padding-left: 0;
            padding-right: 0;
        }
        .sc-inner{
            padding-top: 0;
        }
        .ths-button{
            display: flex;
            width: 95%;
            background: #ffffff;
            padding: 1rem;
            border-radius: 5px;
            box-shadow: 0 4px 8px 0 whitesmoke, 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            cursor: pointer;
            font-family: poppins;
            font-size: 1rem;
            font-weight: 600;
            color: #575757;
            margin-left: 2.5%;
        }
        .ths-button-text{
            width: 90%;
            text-align: left;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .ths-button-icon{
            width: 10%;
            text-align: right;
        }
        .tbi-img{
            transition: 0.5s;
        }
        .tab-head{
            margin-left: 2.5%;
            margin-right: 2.5%;
            display: none;
            position: absolute;
            top: 4.1rem;
            left: 0;
            width: 95%;
            flex-wrap: wrap;
            z-index: 1;
            background: #ffffff;
            border-radius: 5px;
            box-shadow: 0 4px 8px 0 whitesmoke, 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
        .cov-tab-button{
            width: 100%;
        }
        .tab-button{
            justify-content: left;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            font-weight: 500;
            font-size: 1rem;
            line-height: 1;
        }
        .tb-active{
            color: #575757;
            background: none;
            border-radius: 0px;
            border-bottom: none;
        }
        .tb-active .tb-num{
            background: none;
            color: #575757;
            border: 1px solid #F2CB9C;
        }
        .tc-inner{
            padding-left: 1.5%;
            padding-right: 1.5%;
        }
        .tci-part{
            width: 100%;
            margin-bottom: 2.5%;
        }
    }
</style>