import { defineStore } from "pinia";
import axiosClient from "../axios.js";
// import { createSlug } from "@/functions/general.js";

export const useRempahStore = defineStore("rempah", {
    state: () => ({
        // ==================== states used on kamus pangan page and its relation
        allIngredients: null,
        ingredients: null,
        searchBar: {select: {title: 'Semua', value: 'all'}, input: {value: null}},
        categories: null,
        ingredientsShow: null,
        ingredientDetail: null,
        ingredientsByCategory: null,
        abcShow: null,
        limit: 9
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on kamus pangan page and its relation
        async setIngredients(){
            if(!this.allIngredients){
                try{
                    const response = await axiosClient.get(`ingredient/get-all`);
                    const cgres = await axiosClient.get(`ingredient/kategori/get-all`);
                    this.allIngredients = response.data;
                    this.ingredients = response.data;
                    this.categories = cgres.data;
                    // for(var i = 0; i < this.categories.length; i++){
                    //     this.categories[i].slug = createSlug(this.categories[i].name);
                    // }
                    this.showIngredients(this.limit);
                    console.log(this.allIngredients);
                    console.log(this.categories);
                }catch(error){
                    console.log(error);
                }
            }
        },
        async setIngredientDetail(slug){
            if(!this.allIngredients){
                await this.setIngredients();
            }
            this.ingredientDetail = null;
            try{
                const response = await axiosClient.get(`article/` + slug);
                this.ingredientDetail = response.data.ingredients;
                // console.log(this.ingredientDetail);
            }catch(error){
                console.log(error);
            }
        },
        async setIngredientsByCategory(slug){
            if(!this.allIngredients){
                await this.setIngredients();
            }
            var at = [];
            for(var i = 0; i < this.categories.length; i++){
                if(this.categories[i].slug == slug){
                    this.activeCategory = this.categories[i].title;
                }
            }
            for(i = 0; i < this.allIngredients.length; i++){
                if(this.allIngredients[i].category.slug == slug){
                    at.push(this.allIngredients[i]);
                }
            }
            this.ingredientsByCategory = at;
            this.showAbc(this.limit);
            // console.log(this.ingredientsByCategory);
        },
        showIngredients(limit){
            this.ingredientsShow = this.ingredients.slice(0, limit);
        },
        moreIngredients(){
            const ln = this.ingredientsShow.length;
            this.showIngredients((ln + this.limit));
        },
        searchbarSelect(category, slug){
            this.searchBar.select.title = category;
            this.searchBar.select.value = slug;
            this.searchService();
        },
        searchbarInput(value){
            this.searchBar.input.value = value;
            this.searchService();
        },
        searchService(){
            var newsd = [];
            if(this.searchBar.select.value != 'all'){ //if selected category
                for(var i = 0; i < this.allIngredients.length; i++){
                    if(this.allIngredients[i].category){
                        if(this.allIngredients[i].category.title == this.searchBar.select.title){
                            newsd.push(this.allIngredients[i]);
                        }
                    }
                }
            }else{
                newsd = this.allIngredients;
            }
            if(this.searchBar.input.value){
                var result = [];
                var inval = new RegExp(this.searchBar.input.value, 'gi');
                for(i = 0; i < newsd.length; i++){
                    if(newsd[i].title.match(inval)){
                        result.push(newsd[i]);
                    }
                }
                this.ingredients = result;
            }else{
                this.ingredients = newsd;
            }
            this.showIngredients(this.limit);
        },
        showAbc(limit){
            this.abcShow = this.ingredientsByCategory.slice(0, limit);
        },
        moreAbc(){
            const ln = this.abcShow.length;
            this.showAbc((ln + this.limit));
        }
    }
});