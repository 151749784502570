<template>
  <!-- <div class="app">
    <MainNavbar /> -->
    <div class="content">
      <TravelHeader />
      <TravelTravel />
    </div>
    <!-- <MainFooter />
  </div> -->
</template>

<script>
// @ is an alias to /src
// import MainNavbar from '@/components/MainNavbar.vue'
import TravelHeader from '@/components/travel/TravelHeader.vue'
import TravelTravel from '@/components/travel/TravelTravel.vue'

// import MainFooter from '@/components/MainFooter.vue'

export default {
  name: 'TravelPage',
  components: {
    // MainNavbar,
    TravelHeader,
    TravelTravel
    // MainFooter
  }
}
</script>