import { defineStore } from "pinia";
import axiosClient from "../axios.js";
// import { createSlug } from "@/functions/general.js";

export const useBlogStore = defineStore("blog", {
    state: () => ({
        // ==================== states used on blog page and its relation
        allArticles: null,
        articles: null,
        searchBar: {select: {title: 'Semua', value: 'all'}, input: {value: null}},
        categories: null,
        activeCategory: null,
        articlesShow: null,
        articleDetail: null,
        articlesByCategory: null,
        abcShow: null,
        limit: 9
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on blog page and its relation
        async setArticles(){
            if(!this.allArticles){
                try{
                    const response = await axiosClient.get(`artikel/get-all`);
                    const cgres = await axiosClient.get(`artikel/kategori/get-all`);
                    this.allArticles = response.data;
                    this.articles = response.data;
                    this.categories = cgres.data;
                    // for(var i = 0; i < this.categories.length; i++){
                    //     this.categories[i].slug = createSlug(this.categories[i].name);
                    // }
                    this.showArticles(this.limit);
                    console.log(this.allArticles);
                    console.log(this.categories);
                }catch(error){
                    console.log(error);
                }
            }
        },
        async setArticleDetail(slug){
            if(!this.allArticles){
                await this.setArticles();
            }
            this.articleDetail = null;
            try{
                const response = await axiosClient.get(`article/` + slug);
                this.articleDetail = response.data.articles;
                // console.log(this.articleDetail);
            }catch(error){
                console.log(error);
            }
        },
        async setArticlesByCategory(slug){
            if(!this.allArticles){
                await this.setArticles();
            }
            var at = [];
            for(var i = 0; i < this.categories.length; i++){
                if(this.categories[i].slug == slug){
                    this.activeCategory = this.categories[i].title;
                }
            }
            for(i = 0; i < this.allArticles.length; i++){
                if(this.allArticles[i].category.slug == slug){
                    at.push(this.allArticles[i]);
                }
            }
            this.articlesByCategory = at;
            this.showAbc(this.limit);
        },
        showArticles(limit){
            this.articlesShow = this.articles.slice(0, limit);
        },
        moreArticles(){
            const ln = this.articlesShow.length;
            this.showArticles((ln + this.limit));
        },
        searchbarSelect(category, slug){
            this.searchBar.select.title = category;
            this.searchBar.select.value = slug;
            this.searchService();
        },
        searchbarInput(value){
            this.searchBar.input.value = value;
            this.searchService();
        },
        searchService(){
            var newsd = [];
            if(this.searchBar.select.value != 'all'){ //if selected category
                for(var i = 0; i < this.allArticles.length; i++){
                    if(this.allArticles[i].category){
                        if(this.allArticles[i].category.title == this.searchBar.select.title){
                            newsd.push(this.allArticles[i]);
                        }
                    }
                }
            }else{
                newsd = this.allArticles;
            }
            if(this.searchBar.input.value){
                var result = [];
                var inval = new RegExp(this.searchBar.input.value, 'gi');
                for(i = 0; i < newsd.length; i++){
                    if(newsd[i].title.match(inval)){
                        result.push(newsd[i]);
                    }
                }
                this.articles = result;
            }else{
                this.articles = newsd;
            }
            this.showArticles(this.limit);
        },
        showAbc(limit){
            this.abcShow = this.articlesByCategory.slice(0, limit);
        },
        moreAbc(){
            const ln = this.abcShow.length;
            this.showAbc((ln + this.limit));
        }
    }
});