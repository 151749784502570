import router from "@/router";
export function pageScrollTop(){
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}
export function showNotification(title, message, milisecond, bg){
    const date = new Date();
    const time = date.getTime();
    const nt = document.getElementById('notification');
    const tt = document.getElementById('notification-title');
    const mg = document.getElementById('notification-message');
    var background;
    nt.style.display = 'none';
    if(bg){
        background = bg;
    }else{
        background = 'rgb(76, 155, 230)';
    }
    nt.style.background = background;
    nt.setAttribute('time', time);
    setTimeout(function(){
        tt.innerHTML = title;
        mg.innerHTML = message;
        nt.style.display = 'block';
        setTimeout(function(){
            if(nt.getAttribute('time') == time){
                nt.style.display = 'none';
            }
        }, milisecond);
    }, 0);
}
export function setCookie(cname, cvalue, exdays){
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
export function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
        c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
        }
    }
    return "";
}
export function showModal(id){
    const modal = document.getElementById(id);
    modal.classList.add('modal-active');
}
export function hideModals(event){
    var modal = document.getElementsByClassName('modal');
    for(var i = 0; i < modal.length; i++){
        if(event.target == modal[i]){
            modal[i].style.display = 'none';
        }
    }
}
export function hideModal(id){
    const modal = document.getElementById(id);
    modal.classList.remove('modal-active');
}
export function showLogin(){
    const lf = document.getElementById('navbar-login-form');
    // console.log(lf.children[0]);
    if(lf.style.display == 'block'){
        if(lf.children[0].children[1].scrollLeft > 0){
            lf.children[0].children[1].scrollLeft = 0;
        }else{
            lf.style.display = 'none';
        }
    }else{
        lf.style.display = 'block';
    }
    lf.children[0].children[1].scrollLeft = 0;
}
export function hideLogin(){
    const lf = document.getElementById('navbar-login-form');
    lf.style.display = 'none';
}
export function goTo(route){
    router.push(route);
}
export function showTab(button, btnid, hide, show, btactivename, ctactivename){
    var i;
    const bt = document.getElementsByClassName(button);
    const cl = document.getElementsByClassName(hide);
    for(i = 0; i < bt.length; i++){
        bt[i].classList.remove(btactivename);
    }
    for(i = 0; i < cl.length; i++){
        cl[i].classList.remove(ctactivename);
    }
    document.getElementById(btnid).classList.add(btactivename);
    document.getElementById(show).classList.add(ctactivename);
}
export function wordsLimit(words, max){
    if(words.length > max) words = words.substring(0, max) + '...';
    return words;
}
export function removeHTML(html){
    var div = document.createElement("div");
        div.innerHTML = html;
        var text = div.textContent || div.innerText || "";
        return text;
}
export function number_format(number, decimals, dec_point, thousands_sep){
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parsefloating(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}
export function navbarMenuSelect(id, event){
    const button = document.getElementById(id);
    const arrow = button.children[1].children[0];
    if(hasTouch()){
        if(event == 'click'){
            if(button.nextElementSibling.classList.contains('npa-option-active')){
                button.nextElementSibling.classList.remove('npa-option-active');
                button.nextElementSibling.style.height = 0;
                arrow.style.transform = 'rotate(0)';
            }else{
                button.nextElementSibling.classList.add('npa-option-active');
                button.nextElementSibling.style.height = button.nextElementSibling.scrollHeight + 'px';
                arrow.style.transform = 'rotate(180deg)';
            }
        }
    }
    if(!hasTouch()){
        if(event == 'enter'){
            button.nextElementSibling.classList.add('npa-option-active');
            button.nextElementSibling.style.height = button.nextElementSibling.scrollHeight + 'px';
            arrow.style.transform = 'rotate(180deg)';
        }
        if(event == 'leave'){
            button.nextElementSibling.classList.remove('npa-option-active');
            button.nextElementSibling.style.height = 0;
            arrow.style.transform = 'rotate(0)';
        }
    }
}
export function topSearchSelect(id){
    const button = document.getElementById(id);
    const arrow = button.children[2].children[0].children[0];
    if(button.nextElementSibling.classList.contains('floating-active')){
        button.nextElementSibling.classList.remove('floating-active');
        arrow.style.transform = 'rotate(0)';
    }else{
        button.nextElementSibling.classList.add('floating-active');
        arrow.style.transform = 'rotate(180deg)';
    }
}
export function hasTouch() {
    return ( 'ontouchstart' in window ) || ( navigator.maxTouchPoints > 0 ) || ( navigator.msMaxTouchPoints > 0 );
}
export function shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
    }
    return a;
}
export const createSlug = str => str.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-').replace(/^-+|-+$/g, '');
export function scheduleHour(time){
    if(time){
        return time.slice(0, 5);
    }
    // return time.slice(0, 5).replace(':', ' : ');
    // console.log(time);
}
export function accordionToggle(id){
    const bt = document.getElementById(id);
    const pn = bt.nextElementSibling;
    if(pn.style.maxHeight){
        bt.children[1].children[0].style.transform = 'rotate(0deg)';
        pn.style.maxHeight = null;
    }else{
        bt.children[1].children[0].style.transform = 'rotate(180deg)';
        pn.style.maxHeight = pn.scrollHeight + 'px';
    }
}
export function optionToggle(button){
    const floating = document.getElementsByClassName('floating');
    for(var i = 0; i < floating.length; i++){
        const thisbt = floating[i].previousElementSibling;
        if(thisbt.getAttribute('id') != button){
            floating[i].classList.remove('floating-active');
            thisbt.children[2].classList.remove('floating-icon-active');
        }
    }
    const bt = document.getElementById(button);
    if(bt.nextElementSibling.classList.contains('floating-active')){
        bt.nextElementSibling.classList.remove('floating-active');
        bt.children[2].classList.remove('floating-icon-active');
    }else{
        bt.nextElementSibling.classList.add('floating-active');
        bt.children[2].classList.add('floating-icon-active');
    }
}
export function selectOption(sp, option){
    document.getElementById(option.input).value = sp.id;
    document.getElementById(option.name).innerHTML = sp.name;
}
export function clearInput(ic){
    const input = document.getElementsByClassName(ic);
    for(var i = 0; i < input.length; i++){
        input[i].value = '';
    }
}
export function clearCheckBox(ic){
    const checkbox = document.getElementsByClassName(ic);
    for(var i = 0; i < checkbox.length; i++){
        checkbox[i].checked = false;
    }
}
export function clickElement(id){
    document.getElementById(id).click();
}






