import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
// import 'animate.css/animate.css'

createApp(App).use(createPinia()).use(router).mount('#app')

window.onclick = function(e){
    var i;
    if(!e.target.classList.contains('float-button')){
        const float = document.getElementsByClassName('float');
        const icon = document.getElementsByClassName('tbi-img');
        for(i = 0; i < float.length; i++){
            float[i].style.display = 'none';
        }
        for(i = 0; i < icon.length; i++){
            if(icon[i].style.transform == 'rotate(541deg)'){
                icon[i].style.transform = 'rotate(0)';
            }
        }
    }
    if(!e.target.classList.contains('float-button-mobile')){
        if(screen.width <= 750){
            const float = document.getElementsByClassName('float-mobile');
            const icon = document.getElementsByClassName('tbi-img');
            for(i = 0; i < float.length; i++){
                float[i].style.display = 'none';
            }
            for(i = 0; i < icon.length; i++){
                if(icon[i].style.transform == 'rotate(540deg)'){
                    icon[i].style.transform = 'rotate(0)';
                }
            }
        }
    }
    if(!e.target.classList.contains('floating-button')){
        const floating = document.getElementsByClassName('floating');
        for(i = 0; i < floating.length; i++){
            floating[i].classList.remove('floating-active');
        }
        const arrow = document.getElementsByClassName('floating-arrow');
        for(i = 0; i < arrow.length; i++){
            arrow[i].children[0].style.transform = 'rotate(0)';
        }
        const icon = document.getElementsByClassName('floating-icon');
        for(i = 0; i < icon.length; i++){
            icon[i].classList.remove('floating-icon-active');
        }
    }
}

let touchstartX = 0;
let touchendX = 0;
const nbmob = document.getElementById('navbar-menu');
const nbmobbg = document.getElementById('navbar-menu-bg');
function handleGesture() {
    if(touchendX > touchstartX){
        document.getElementById('navbar-menu').style.left = '110%';
        document.getElementById('navbar-menu-bg').style.width = '0';
    }
}
nbmob.addEventListener('touchstart', e => {
    touchstartX = e.changedTouches[0].screenX
});
nbmob.addEventListener('touchend', e => {
    touchendX = e.changedTouches[0].screenX
    handleGesture();
});
nbmobbg.addEventListener('touchstart', e => {
    touchstartX = e.changedTouches[0].screenX
});
nbmobbg.addEventListener('touchend', e => {
    touchendX = e.changedTouches[0].screenX
    handleGesture();
});
// =========================================== when window scrolled
window.onscroll = function(){
    const gotop = document.getElementsByClassName('go-top'); // <================== scroll top icon
    var i;
    if(document.body.scrollTop > 100 || document.documentElement.scrollTop > 100){
        for(i = 0; i < gotop.length; i++){
            if(screen.width > 1500){
                gotop[i].style.bottom = '5%';
                // gotop[i].style.width = '75px';
                // gotop[i].style.height = '75px';
            }else if(screen.width > 750){
                gotop[i].style.bottom = '5%';
                // gotop[i].style.width = '50px';
                // gotop[i].style.height = '50px';
            }else{
                gotop[i].style.bottom = '5%';
                // gotop[i].style.width = '50px';
                // gotop[i].style.height = '50px';
            }
        }
    }else{
        for(i = 0; i < gotop.length; i++){
            gotop[i].style.bottom = '-15%';
            // gotop[i].style.width = '50px';
            // gotop[i].style.height = '50px';
        }
    }
}
// =========================================== when window resized
window.onresize = function(){
    // ======================================= home page carousel starts
    const carousel = document.getElementsByClassName('carousel');
    var i;
    for(i = 0; i < carousel.length; i++){
        carousel[i].scrollLeft = 0;
    }
    // ======================================= home page carousel ends
    // ======================================= tab button in the resep page starts
    const mobres = document.getElementsByClassName('mobile-noflex');
    if(screen.width <= 750){
        for(i = 0; i < mobres.length; i++){
            mobres[i].style.display = 'none';
        }
        const icon = document.getElementsByClassName('tbi-img');
        for(i = 0; i < icon.length; i++){
            if(icon[i].style.transform == 'rotate(540deg)'){
                icon[i].style.transform = 'rotate(0)';
            }
        }
    }else{
        for(i = 0; i < mobres.length; i++){
            mobres[i].style.display = 'flex';
        }
    }
    // ======================================= tab button in the resep page ends
}
// =========================================== after page loaded
window.onload = function(){
    // setCarousel();
}

// =========================================== functions
// function setCarousel(){
//     // ======================================= carousel home page starts
//     let isDown = false;
//     let startX;
//     let scrollLeft;
//     const slider = document.querySelector('.carousel');
//     const end = () => {
//         isDown = false;
//     slider.classList.remove('active');
//     }
//     const start = (e) => {
//     isDown = true;
//     slider.classList.add('active');
//     startX = e.pageX || e.touches[0].pageX - slider.offsetLeft;
//     scrollLeft = slider.scrollLeft;	
//     }
//     const move = (e) => {
//         if(!isDown) return;
//     e.preventDefault();
//     const x = e.pageX || e.touches[0].pageX - slider.offsetLeft;
//     const dist = (x - startX);
//     slider.scrollLeft = scrollLeft - dist;
//     }
//     (() => {
//         slider.addEventListener('mousedown', start);
//         slider.addEventListener('touchstart', start);
//         slider.addEventListener('mousemove', move);
//         slider.addEventListener('touchmove', move);
//         slider.addEventListener('mouseleave', end);
//         slider.addEventListener('mouseup', end);
//         slider.addEventListener('touchend', end);
//     })();
//     // ======================================= carousel home page ends
// }



