<template>
    <div class="content">
        <BlogDetail />
        <BlogRelated />
    </div>
</template>

<script setup>
    import BlogDetail from '@/components/blog/BlogDetail.vue'
    import BlogRelated from '@/components/blog/BlogRelated.vue'
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
</script>

