<template>
    <section v-if="blogDetailRelated">
        <div class="section" v-if="blogDetailRelated[0]">
            <div class="section-header">
                <h2>Related Blog</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
            <div class="section-content">
                <!-- {{ blogDetailRelated[0] }} -->
                <div class="sc-inner">
                    <div class="sci-list">
                        <div class="article-list" v-if="blogDetailRelated[0]">
                            <div class="al-image">
                                <img :src="imgsrc + blogDetailRelated[0].picture" alt="food traveller">
                            </div>
                            <div class="al-detail">
                                <div class="al-lable">
                                    <span>{{ blogDetailRelated[0].category_name }}</span>
                                </div>
                                <div class="al-title">
                                    <router-link :to="'/blog/detail/' + blogDetailRelated[0].id" @click="updateDetail(blogDetailRelated[0].id)">
                                        <h3>{{ blogDetailRelated[0].title }}</h3>
                                    </router-link>
                                </div>
                                <div class="al-more">
                                    <router-link :to="'/blog/detail/' + blogDetailRelated[0].id" @click="updateDetail(blogDetailRelated[0].id)">Readmore</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="article-list al-bottom" v-if="blogDetailRelated[1]">
                            <div class="al-image">
                                <img :src="imgsrc + blogDetailRelated[1].picture" alt="food traveller">
                            </div>
                            <div class="al-detail">
                                <div class="al-lable">
                                    <span>{{ blogDetailRelated[1].category_name }}</span>
                                </div>
                                <div class="al-title">
                                    <router-link :to="'/blog/detail/' + blogDetailRelated[1].id" @click="updateDetail(blogDetailRelated[1].id)">
                                        <h3>{{ blogDetailRelated[1].title }}</h3>
                                    </router-link>
                                </div>
                                <div class="al-more">
                                    <router-link :to="'/blog/detail/' + blogDetailRelated[1].id" @click="updateDetail(blogDetailRelated[1].id)">Readmore</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sci-main" v-if="blogDetailRelated[2]">
                        <div class="tcp-img">
                            <img :src="imgsrc + blogDetailRelated[2].picture" alt="food traveller">
                            <div class="sci-gradient"></div>
                        </div>
                        <div class="sm-caption">
                            <div class="sm-lable">
                                <span>{{ blogDetailRelated[2].category_name }}</span>
                            </div>
                            <div class="sm-title">
                                <router-link :to="'/blog/detail/' + blogDetailRelated[2].id" @click="updateDetail(blogDetailRelated[2].id)">
                                    <h3>{{ blogDetailRelated[2].title }}</h3>
                                </router-link>
                            </div>
                            <div class="sm-more">
                                <router-link :to="'/blog/detail/' + blogDetailRelated[2].id" @click="updateDetail(blogDetailRelated[2].id)">Readmore</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sc-footer">
                    <router-link to="/blog">Load more travel blog</router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { useRoute } from 'vue-router'
    import { computed } from "@vue/runtime-core";
    import { useArticleStore } from "../../stores/article";
    // import BlogRelated from '@/components/blog/BlogRelated.vue'
    const blogDetailRelated = computed(() => useArticleStore().blogDetailRelated);
    useArticleStore().setRelated(useRoute().params.id, 3); // (articles.id, limit)
    function updateDetail(id){
        useArticleStore().setBlogDetail(id);
        useArticleStore().setRelated(id, 3); // (articles.id, limit)
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    const imgsrc = process.env.VUE_APP_ROOT_API + 'images/articles/';
</script>

<style scoped>
    .section{
        width: 100%;
        min-height: 15rem;
        padding-top: 2.5rem;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        padding-bottom: 2.5rem;
        color: #1B4674;
        transition: 0.2s;
    }
    .section h2{
        font-family: poppins;
        font-size: 2.625rem;
        font-weight: 700;
        margin: 0;
        margin-top: 1rem;
        line-height: 1;
        transition: 0.2s;
    }
    .section p{
        margin-top: 1.6875rem;
        font-size: 1.5rem;
        transition: 0.2s;
    }
    .small-title{
        color: #75C6EF;
        font-size: 1.125rem;
        font-weight: 600;
    }
    .section-header{
        /* display: flex; */
    }
    .sh-left{
        width: 50%;
        transition: 0.2s;
    }
    .strip{
        width: 10%;
        height: 0.5rem;
        background: #CD4051;
    }
    .sh-right{
        width: 50%;
        text-align: right;
        padding-top: 2.5rem;
        transition: 0.2s;
    }
    .sh-right a{
        text-decoration: underline;
        color: #575757;
        font-size: 1rem;
        font-weight: 600;
    }
    .section-content{
        width: 100%;
        /* height: 100%; */
        margin-top: 2.5rem;
        transition: 0.2s;
    }
    .sc-inner{
        display: flex;
        width: 100%;
        /* max-height: 26.5rem; */
        /* border: 1px solid green; */
        transition: 0.2s;
    }
    .sci-list{
        width: 50%;
        height: 100%;
        height: 100%;
        padding-right: 1rem;
        transition: 0.2s;
    }
    .article-list{
        width: auto;
        height: 12rem;
        display: flex;
        flex-wrap: wrap;
        transition: 0.2s;
    }
    .al-bottom{
        margin-top: 2rem;
    }
    .al-image{
        width: 31%;
        height: 100%;
        transition: 0.2s;
    }
    .al-image img{
        width: 100%;
        height: 100%;
        max-height: 100%;
        border-radius: 10px;
        transition: 0.2s;
    }
    .al-detail{
        position: relative;
        width: 69%;
        padding-left: 2rem;
        padding-right: 2.5rem;
        transition: 0.2s;
    }
    .al-lable{
        padding-top: 0.5rem;
        white-space: nowrap;
        /* border: 1px solid green; */
        /* overflow: hidden; */
        text-overflow: ellipsis;
        /* min-height: 3.6rem; */
        /* max-width: 100px; */
        transition: 0.2s;
    }
    .al-lable span{
        background: #75C6EF;
        color: #ffffff;
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        border-radius: 5rem;
        /* white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; */
        font-size: 0.9rem;
    }
    .al-title{
        position: absolute;
        left: 2rem;
        bottom: 1rem;
        width: 90%;
        transition: 0.2s;
    }
    .al-title a{
        text-decoration: none;
        color: #1B4674;
    }
    .al-title h3{
        font-family: poppins;
        font-size: 2rem;
        font-weight: 700;
        margin-top: 1rem;
        margin-bottom: 1.5rem;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: 0.2s;
    }
    .al-more{
        position: absolute;
        left: 2rem;
        bottom: 0;
        transition: 0.2s;
    }
    .al-more a{
        color: #75C6EF;
        text-decoration: underline;
    }
    .sci-main{
        position: relative;
        width: 50%;
        overflow: hidden;
        transition: 0.2s;
    }
    .tcp-img{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .tcp-img img{
        width: 100%;
        /* min-height: 24rem; */
        height: 100%;
        max-height: 26.5rem;
        border-radius: 10px;
    }
    .sci-gradient{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 45%;
        background-image: linear-gradient(transparent, #000000);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    /* .sci-main img{
        width: 100%;
        height: 100%;
        border-radius: 10px;
    } */
    .sm-caption{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .sm-lable{
        position: absolute;
        top: 2rem;
        left: 2rem;
    }
    .sm-lable span{
        background: #75C6EF;
        color: #ffffff;
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        border-radius: 5rem;
        font-size: 0.9rem;
    }
    .sm-title{
        position: absolute;
        width: 75%;
        left: 2rem;
        bottom: 2.3rem;
        color: #ffffff;
        font-family: poppins;
        font-size: 2rem;
        font-weight: 700;
        transition: 0.2s;
    }
    .sm-title a{
        text-decoration: none;
        color: #ffffff;
    }
    .sm-title h3{
        margin: 0;
    }
    .sm-more{
        position: absolute;
        left: 2rem;
        bottom: 1rem;
        transition: 0.2s;
    }
    .sm-more a{
        color: #ffffff;
        text-decoration: underline;
    }
    .sc-footer{
        text-align: left;
        padding-top: 2rem;
        font-size: 1rem;
        font-weight: 600;
    }
    .sc-footer a{
        text-decoration: underline;
        color: #575757;
    }
    @media only screen and (max-width: 1500px){
        .section{
            padding-left: 5%;
            padding-right: 5%;
        }
    }
    @media only screen and (max-width: 1250px){
        .al-image{
            width: 40%;
        }
        .al-detail{
            width: 60%;
        }
    }
    @media only screen and (max-width: 1050px){
        .section h2{
            font-size: 2rem;
        }
        .section-content{
            margin-top: 2.6rem;
        }
        .sc-inner{
            max-height: 22rem;
        }
        .article-list{
            height: 10rem;
        }
        .al-title h3{
            font-size: 1.2rem;
        }
        .sm-title{
            left: 1rem;
            width: 90%;
            font-size: 1.5rem;
        }
        /* .sm-title{
        } */
        .sm-more{
            left: 1rem;
        }
    }
    @media only screen and (max-width: 850px){
        .sci-list{
            width: 100%;
        }
        .sci-main{
            width: 0;
        }
    }
    @media only screen and (max-width: 750px){
        .section{
            padding-top: 0;
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        /* section h2{
            font-size: 1.5rem;
        } */
        .section p{
            font-size: 1rem;
        }
        .sh-left{
            width: 100%;
        }
        .sh-right{
            width: 0;
            overflow: hidden;
        }
        .al-image{
            width: 50%;
        }
        .al-detail{
            width: 50%;
            padding-left: 1rem;
        }
        .al-title{
            left: 1rem;
            bottom: 0.5rem;
        }
        .al-more{
            left: 1rem;
            bottom: 0.5rem;
        }
        .sc-footer{
            display: none;
        }
    }
    @media only screen and (max-width: 390px){
        .sc-inner{
            max-height: unset;
        }
        .sci-list{
            padding-right: 0;
        }
        .article-list{
            height: auto;
            border: 1px solid whitesmoke;
            border-radius: 10px 10px 0 0;
        }
        .al-image{
            width: 100%;
        }
        .al-image img{
            border-radius: 10px 10px 0 0;
        }
        .al-detail{
            width: 100%;
            min-height: 10rem;
            padding-top: 1rem;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
        .al-lable{
            padding-top: 1rem;
        }
        .al-title{
            /* left: 0.5rem; */
            bottom: 0.5rem;
        }
        .al-more{
            /* left: 0.5rem; */
            bottom: 0.5rem;
        }
    }
</style>