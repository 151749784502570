<template>
    <section>
        <div class="section-bg"></div>
            <div class="section-content">
                <KamusRempahDetailSkeleton v-if="!kamusRempahDetail" />
                <div class="sc-inner" v-else>
                    <div class="sci-left" v-if="kamusRempahDetail[0]">
                        <div class="sl-head">
                            <p class="small-title">{{ kamusRempahDetail[0].category_name }}</p>
                            <h1 class="main-title">{{ kamusRempahDetail[0].title }}</h1>
                            <p class="p-date">Food traveller, {{ epochDMY(Date.parse(kamusRempahDetail[0].created_at)) }}</p>
                        </div>
                        <div class="sl-body">
                            <div class="sl-img">
                                <img :src="imgsrc + kamusRempahDetail[0].picture" alt="food traveller" />
                            </div>
                            <div class="sl-content">
                                <div v-html="kamusRempahDetail[0].description"></div>
                            </div>
                            <div class="sl-tips">
                                <div class="sl-tips-inner" v-for="ustct in kamusRempahDetail[0].user_target_category" :key="ustct.id">
                                    <div class="sti-part">
                                        <div v-if="ustct.content.length > 0">
                                            <h2>{{ ustct.name }}</h2>
                                            <div v-for="ut in ustct.content" :key="ut.id">
                                                <div class="sl-tips-part" v-if="ut.suggestion == 1">
                                                    <div class="stp-inner stp-suggest">
                                                        <div class="stp-icon">
                                                            <img src="../../assets/images/icons/check-sky.png" alt="food traveller">
                                                        </div>
                                                        <div class="stp-text">
                                                            <p>{{ ut.description }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="sl-tips-part" v-if="ut.suggestion == 2">
                                                    <div class="stp-inner stp-warning">
                                                        <div class="stp-icon">
                                                            <img src="../../assets/images/icons/warning-yellow.png" alt="food traveller">
                                                        </div>
                                                        <div class="stp-text">
                                                            <p>{{ ut.description }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="sl-tips-part" v-if="ut.suggestion == 3">
                                                    <div class="stp-inner stp-danger">
                                                        <div class="stp-icon">
                                                            <img src="../../assets/images/icons/times-red.png" alt="food traveller">
                                                        </div>
                                                        <div class="stp-text">
                                                            <p>{{ ut.description }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="sc-share">
                            <h2>Share to</h2>
                            <div class="sc-share-inner">
                                <div class="scs-part">
                                    <a href="https://www.facebook.com" target="_blank">
                                        <img src="../../assets/images/icons/cc-facebook.png" alt="food traveller">
                                    </a>
                                </div>
                                <div class="scs-part">
                                    <a href="https://www.twitter.com" target="_blank">
                                        <img src="../../assets/images/icons/cc-twitter.png" alt="food traveller">
                                    </a>
                                </div>
                                <div class="scs-part">
                                    <a href="https://www.linkedin.com" target="_blank">
                                        <img src="../../assets/images/icons/cc-linkedin.png" alt="food traveller">
                                    </a>
                                </div>
                                <div class="scs-part">
                                    <a href="https://www.instagram.com" target="_blank">
                                        <img src="../../assets/images/icons/cc-instagram.png" alt="food traveller">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sci-right">
                        <div class="sr-part sr-categories" v-if="categories">
                            <h2>Related Rempah</h2>
                            <div class="sr-link" v-for="category in categories" :key="category">
                                <router-link :to="'/kamus-pangan/category/' + category.slug">
                                    <h3>{{ category.title }}</h3>
                                </router-link>
                            </div>
                        </div>
                        <!-- <div class="sr-part sr-tag">
                            <h2>Tags</h2>
                            <div class="sr-tag-inner">
                                <router-link to="">Bandung</router-link>
                                <router-link to="">Culinary Night</router-link>
                                <router-link to="">2022</router-link>
                                <router-link to="">Hotel Murah</router-link>
                                <router-link to="">Culinary Night</router-link>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
    </section>
</template>

<script setup>
    import { useRoute } from 'vue-router'
    import { computed } from "@vue/runtime-core";
    import { useIngredientStore } from "../../stores/ingredient";
    import { useRempahStore } from "../../stores/rempah";
    import KamusRempahDetailSkeleton from '../skeletons/KamusRempahDetailSkeleton.vue';
    const kamusRempahDetail = computed(() => useIngredientStore().kamusRempahDetail);
    const categories = computed(() => useRempahStore().categories);
    useIngredientStore().setKrDetail(useRoute().params.id);
    useRempahStore().setIngredients();
    function epochDMY(ts){
        const months = ["Januari", "Pebruari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
        var dt = new Date(ts);
        var d = dt.getDay();
        var m = months[dt.getMonth()];
        var y = dt.getFullYear();
        return d + ' ' + m +' ' + y;
    }
    const imgsrc = process.env.VUE_APP_ROOT_API + 'images/ingredient/';
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
</script>

<style scoped>
    section{
        position: relative;
        width: 100%;
        min-height: 15rem;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        color: #1B4674;
        transition: 0.2s;
    }
    .section-bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 39rem;
        background: #FEF8F0;
        z-index: -1;
        transition: 0.2s;
    }
    .small-title{
        color: #75C6EF;
        font-weight: 600;
        font-size: 1.125rem;
    }
    .main-title{
        font-family: poppins;
        font-size: 3.25rem;
        font-weight: 700;
        margin-top: 1rem;
        margin-bottom: 1rem;
        transition: 0.2s;
    }
    .p-date{
        margin: 0;
        margin-top: 1.6875rem;
        font-size: 1rem;
        font-weight: 400;
        width: 31.9375rem;
        transition: 0.2s;
    }
    .section-content{
        width: 100%;
        height: 100%;
        padding-left: 11.25rem;
        padding-right: 11.25rem;
        transition: 0.2s;
    }
    .sc-inner{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        padding-top: 2rem;
        transition: 0.2s;
    }
    .sci-left{
        width: 60%;
        transition: 0.2s;
    }
    .sl-head{
        padding-left: 1rem;
    }
    .sl-body{
        padding-top: 2rem;
    }
    .sl-img{
        margin-bottom: 2rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .sl-img img{
        width: auto;
        width: 100%;
        transition: 0.2s;
    }
    .sl-content{
        text-align: justify;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .sl-content img{
        max-width: 100%;
    }
    .slc-part{
        margin-bottom: 2rem;
    }
    .slc-part h2{
        font-family: poppins;
        font-size: 1.75rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }
    .slc-part ul{
        list-style-type: none;
        padding-left: 0;
    }
    .slc-part ol{
        padding-left: 1rem;
    }
    .slc-part li{
        font-size: 1rem;
    }
    .sl-tips{
        /* display: flex;
        flex-direction: row;
        flex-wrap: wrap; */
        width: 100%;
    }
    .sl-tips-inner{
        width: 100%;
        transition: 0.2s;
        padding: 1%;
    }
    .sti-part h2{
        margin: 0;
        padding-left: 1%;
        padding-right: 1%;
        font-size: 1.1rem;
    }
    .sl-tips-part{
        /* border: 1px solid skyblue; */
        /* width: 50%; */
        padding: 1%;
        /* padding-top: 1%;
        padding-bottom: 1%; */
        transition: 0.2s;
    }
    .stp-inner{
        display: flex;
        flex-wrap: nowrap;
        /* border: 1px solid #75C6EF; */
        border-radius: 5px;
        padding: 1%;
        /* background: #75c6ef31; */
        /* height: 5rem; */
        /* overflow: auto; */
    }
    .stp-suggest{
        border: 1px solid #75C6EF;
        background: #75c6ef31;
    }
    .stp-warning{
        border: 1px solid #fce6ab;
        background: #fce6ab2a;
    }
    .stp-danger{
        border: 1px solid #EA476A;
        background: #ea476a31;
    }
    .stp-icon{
        display: flex;
        width: 10%;
        text-align: center;
        align-items: center;
        /* padding-top: 3.7%; */
        padding-left: 2%;
        /* border: 1px solid green; */
    }
    .stp-icon img{
        max-width: 23px;
        padding: 1%;
    }
    .stp-text{
        display: flex;
        width: 90%;
        height: 100%;
        overflow: auto;
        padding: 1%;
        /* align-items: center; */
    }
    .stp-text p{
        margin: 0;
    }
    .sc-share{
        margin-top: 2rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .sc-share h2{
        font-family: poppins;
        font-size: 1rem;
        font-weight: 700;
    }
    .sc-share-inner{
        display: flex;
        flex-wrap: nowrap;
        padding-top: 1rem;
    }
    .scs-part{
        margin-right: 1rem;
    }
    .sci-right{
        width: 40%;
        padding-top: 36rem;
        padding-left: 5rem;
        transition: 0.2s;
    }
    .sr-check{
        background: #ffffff;
        min-height: 10rem;
        width: 100%;
        padding: 2rem;
        margin-bottom: 2rem;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.12), 0 6px 20px 0 rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        transition: 0.2s;
    }
    .sr-check h2{
        font-family: poppins;
        font-size: 1.25rem;
        font-weight: 700;
        color: #1B4674;
        margin-bottom: 1rem;
    }
    .hc-list{
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: 0.5rem;
    }
    .hcl-icon{
        width: 7.5%;
        max-width: 22px;
    }
    .hcl-icon img{
        width: 92.5%;
        max-width: 15px;
    }
    .hcl-text{
        width: 85%;
        padding-left: 1rem;
    }
    .sr-part h2{
        font-family: poppins;
        font-weight: 700;
        font-size: 1.25rem;
        color: #bbbbbb;
        margin: 0;
    }
    .sr-categories{
        width: 100%;
        transition: 0.2s;
        margin-bottom: 2rem;
    }
    .sr-link{
        margin-top: 1rem;
    }
    .sr-link a{
        color: #1B4674;
        text-decoration: none;
    }
    .sr-tag{
        width: 100%;
        transition: 0.2s;
        margin-bottom: 2rem;
    }
    .sr-tag-inner{
        display: flex;
        flex-wrap: wrap;
        padding-top: 1rem;
        width: 100%;
    }
    .sr-tag-inner a{
        font-size: 1rem;
        color: #575757;
        border: 1px solid #575757;
        border-radius: 50px;
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        text-decoration: none;
        margin-top: 1rem;
        margin-right: 1rem;
    }
    @media only screen and (max-width: 1500px){
        .section-content{
            padding-left: 3.9%;
            padding-right: 3.9%;
        }
        .section-bg{
            height: 31rem;
        }
        .sl-tips{
            padding-left: 0.5%;
            padding-right: 0.5%;
        }
        .main-title{
            font-size: 2.6rem;
        }
        .p-date{
            margin-top: 1rem;
        }
        .sc-share{
            padding-left: 1.5%;
            padding-right: 1.5%;
        }
    }
    @media only screen and (max-width: 1050px){
        .sc-inner{
            padding-top: 0;
        }
        .sci-left{
            width: 100%;
        }
        .sl-img img{
            width: 100%;
        }
        .main-title{
            font-size: 2rem;
        }
        .p-date{
            width: auto;
        }
        .sci-right{
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding-top: 2rem;
            padding-left: 1.5%;
            padding-right: 1.5%;
        }
        .sr-check{
            margin-bottom: 1.5rem;
        }
        .sr-categories{
            width: 50%;
            margin-bottom: 0;
        }
        .sr-tag{
            width: 50%;
            margin-bottom: 0;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding-top: 1rem;
        }
        .section-bg{
            height: 19rem;
        }
        .section-content{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .sl-head{
            padding-left: 0;
        }
        .sl-img{
            padding-left: 0;
            padding-right: 0;
        }
        .sl-content{
            padding-left: 0;
            padding-right: 0;
        }
        .sl-tips{
            padding-left: 0;
            padding-right: 0;
        }
        .sl-tips-inner{
            width: 100%;
            padding: 0;
        }
        .sl-tips-part{
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 2.5%;
        }
        .sc-share{
            padding-left: 0;
            padding-right: 0;
        }
        .sci-right{
            padding-top: 1rem;
            padding-left: 0;
            padding-right: 0;
        }
        .sr-check{
            padding: 0;
            margin-bottom: 1rem;
            box-shadow: unset;
            border-radius: 0;
        }
        .sr-categories{
            width: 100%;
        }
        .sr-tag{
            width: 100%;
        }
    }
    /*  */
</style>