<template>
    <section>
        <div class="section-content">
            <div class="sc-inner">
                <div class="sci-left">
                    <p class="sci-small-title">Melinda Hospital Mother Care</p>
                    <h2 class="sci-title">Tell us what do you need?</h2>
                    <div class="sci-detail">
                        <div class="sci-detail-part">
                            <h3>Office</h3>
                            <p>Jl.Pajajaran 46, Bandung Jawa Barat Indonesia</p>
                        </div>
                        <div class="sci-detail-part">
                            <h3>Contact</h3>
                            <p>Hunting  : (022) 4222788</p>
                            <p>Pendaftaran : (022) 4209088</p>
                            <p>Fax       : (022) 4215700</p>
                        </div>
                        <div class="sci-detail-part">
                            <h3>Email</h3>
                            <p>info@melindahospital.com</p>
                        </div>
                    </div>
                </div>
                <div class="sci-right">
                    <div class="sci-form">
                        <div class="sci-input">
                            <div class="sci-label">Department</div>
                            <select name="" id="">
                                <option value="">Select Department</option>
                                <option value="marketing">Marketing</option>
                                <option value="it">IT</option>
                            </select>
                        </div>
                        <div class="sci-input">
                            <div class="sci-label">Full Name</div>
                            <input type="text" placeholder="Full Name" />
                        </div>
                        <div class="sci-input">
                            <div class="sci-label">Email Address</div>
                            <input type="text" placeholder="Email Address" />
                        </div>
                        <div class="sci-input">
                            <div class="sci-label">Phone Number</div>
                            <input type="text" placeholder="Phone Number" />
                        </div>
                        <div class="sci-input">
                            <div class="sci-label">Messages</div>
                            <textarea name="" id="" cols="30" rows="10"></textarea>
                        </div>
                        <div class="sci-input">
                            <button>
                                Send Us
                                <img src="../../assets/images/icons/wacright.png" alt="food traveller">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'ContactForm',
    methods: {
        // 
    }
}
</script>

<style scoped>
    section{
        position: relative;
        width: 100%;
        min-height: 15rem;
        padding-top: 2.5rem;
        /* padding-bottom: 2.5rem; */
        color: #1B4674;
        background: #ffffff;
        transition: 0.2s;
    }
    .section-header{
        text-align: left;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        transition: 0.2s;
    }
    .section-content{
        width: 100%;
        height: 100%;
        margin-top: 2rem;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        transition: 0.2s;
    }
    .sc-inner{
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        width: 100%;
        padding-top: 2rem;
        transition: 0.2s;
    }
    .sci-left{
        width: 50%;
        /* padding-right: 1rem; */
        transition: 0.2s;
    }
    .sci-right{
        width: 50%;
        color: #575757;
        transition: 0.2s;
    }
    .sci-small-title{
        color: #75C6EF;
        font-size: 1.125rem;
        font-weight: 600;
    }
    .sci-title{
        font-family: poppins;
        font-size: 3rem;
        font-weight: 700;
        width: 75%;
        line-height: 1;
        margin-top: 1rem;
        transition: 0.2s;
    }
    .sci-detail{
        display: flex;
        flex-wrap: wrap;
        margin-top: 2rem;
        color: #575757;
        transition: 0.2s;
    }
    .sci-detail-part{
        width: 50%;
        padding: 1rem;
        padding-left: 0;
        transition: 0.2s;
    }
    .sci-detail-part h3{
        margin-bottom: 0.5rem;
    }
    .sci-detail-part p{
        margin: 0;
        margin-bottom: 0.5rem;
        line-height: 1;
    }
    .sci-input{
        margin-bottom: 1rem;
        width: 100%;
    }
    .sci-label{
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 1rem;
    }
    .sci-input select, input, textarea{
        width: 100%;
        border: none;
        border-bottom: 1px solid #D3D3D3;
        outline: none;
        padding-left: none;
        color: #575757;
    }
    .sci-input select{
        height: 2rem;
    }
    .sci-input input{
        height: 2rem;
    }
    .sci-input textarea{
        height: 5rem;
        font-family: roboto;
    }
    .sci-input button{
        font-size: 1.1875;
        min-width: 150px;
        width: 25%;
        height: 2rem;
        padding: 0.5rem;
        background: #1B4674;
        color: #ffffff;
        border: none;
        border-radius: 50px;
    }
    .sci-input button:hover{
        background: #2c5b8d;
    }
    .sci-input button img{
        float: right;
        padding-right: 1rem;
    }
    @media only screen and (max-width: 1500px){
        .section-header{
            padding-left: 5%;
            padding-right: 5%;
        }
        .section-content{
            padding-left: 5%;
            padding-right: 5%;
            margin-top: 0;
        }
    }
    @media only screen and (max-width: 1050px){
        .sc-inner{
            padding-top: 0;
        }
        .sci-left{
            width: 100%;
        }
        .sci-title{
            font-size: 2rem;
            width: 90%;
            margin-bottom: 1rem;
        }
        .sci-detail{
            margin-top: 0;
        }
        .sci-detail-part{
            width: 33.33%;
            padding-top: 0;
            padding-bottom: 0;
        }
        .sci-right{
            width: 100%;
            margin-top: 2rem;
        }
    }
    @media only screen and (max-width: 851px){
        /*  */
    }
    @media only screen and (max-width: 710px){
        section{
            padding-top: 1rem;
        }
        .section-header{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .section-content{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .sci-detail-part{
            width: 100%;
        }
    }
</style>