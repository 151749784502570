<template>
    <section>
        <div class="section-header">
            <p class="sm-title">Gallery</p>
            <h2>Gallery Place for Food</h2>
        </div>
        <div class="section-content">
            <!-- {{ homeGc1 }} -->
            <HomeGallerySkeleton v-if="!homeGc1"/>
            <div class="sc-inner" v-else>
                <div class="sci-left">
                    <div class="sci-left-top" v-if="homeGc1">
                        <div class="tcp-img">
                            <img :src="imgsrc + homeGc1.img_landscape" alt="food traveller">
                            <div class="sci-gradient"></div>
                        </div>
                        <div class="sci-caption sci-caption-left">
                            <router-link to="/gallery">
                                <h3>{{ homeGc1.title }}</h3>
                            </router-link>
                        </div>
                    </div>
                    <div class="sci-left-bottom" v-if="homeGc2">
                        <div class="tcp-img">
                            <img :src="imgsrc + homeGc2.img_landscape" alt="food traveller">
                            <div class="sci-gradient"></div>
                        </div>
                        <div class="sci-caption sci-caption-left">
                            <router-link to="/gallery">
                                <h3>{{ homeGc2.title }}</h3>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="sci-right" v-if="homeGc3">
                    <div class="tcp-img">
                        <img :src="imgsrc + homeGc3.img_portrait" alt="food traveller">
                        <div class="sci-gradient"></div>
                    </div>
                    <div class="sci-caption sci-caption-right">
                        <router-link to="/gallery">
                            <h3>{{ homeGc3.title }}</h3>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="sc-browse">
                <router-link to="/">Browse near awesome place</router-link>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { computed } from "@vue/runtime-core";
    import { useGalleryStore } from "../../stores/gallery";
    import HomeGallerySkeleton from "../skeletons/HomeGallerySkeleton.vue";
    const homeGc1 = computed(() => useGalleryStore().homeGc1);
    const homeGc2 = computed(() => useGalleryStore().homeGc2);
    const homeGc3 = computed(() => useGalleryStore().homeGc3);
    useGalleryStore().setHgc1(3); // (limit)
    const imgsrc = process.env.VUE_APP_ROOT_API + 'images/gallery/category/';
</script>

<style scoped>
    section{
        width: 100%;
        min-height: 50rem;
        padding-top: 2.5rem;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        padding-bottom: 2.5rem;
        color: #1B4674;
        transition: 0.2s;
    }
    section h2{
        font-family: poppins;
        font-size: 2.625rem;
        font-weight: 700;
        margin: 0;
        margin-top: 1rem;
        margin-bottom: 1rem;
        line-height: 1;
        transition: 0.2s;
    }
    section p{
        margin-top: 1.6875rem;
        font-size: 1.5rem;
    }
    .sm-title{
        color: #75C6EF;
        font-size: 1.125rem;
        font-weight: 600;
        margin: 0;
    }
    .section-content{
        width: 100%;
        height: 100%;
        margin-top: 2.5rem;
    }
    .sc-inner{
        display: flex;
        width: 100%;
        height: 67rem;
        transition: 0.2s;
    }
    /* .sc-inner img{
        width: 100%;
        height: 100%;
        border-radius: 10px;
    } */
    .sci-caption{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        color: #ffffff;
        font-family: poppins;
        font-weight: 700;
    }
    .sci-caption a{
        text-decoration: none;
        color: #ffffff;
    }
    .sci-caption-left{
        font-size: 1.75rem;
        padding: 2rem;
    }
    .sci-caption-right{
        font-size: 2.625rem;
        padding: 2.5rem;
    }
    .sci-left{
        width: 45%;
        height: 100%;
        padding-right: 1rem;
        transition: 0.2s;
    }
    .sci-left-top{
        position: relative;
        width: 100%;
        height: 49%;
        transition: 0.2s;
    }
    .tcp-img{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .tcp-img img{
        width: 100%;
        /* min-height: 24rem; */
        height: 100%;
        border-radius: 10px;
        /* border: 1px solid green; */
    }
    .sci-gradient{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 45%;
        background-image: linear-gradient(transparent, #000000);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .sci-left-bottom{
        position: relative;
        width: 100%;
        height: 49.5%;
        margin-top: 1rem;
        transition: 0.2s;
    }
    .sci-right{
        position: relative;
        width: 55%;
        height: 100%;
        overflow: hidden;
        transition: 0.2s;
    }
    .sc-browse{
        margin-top: 1.6875rem;
    }
    .sc-browse a{
        text-decoration: underline;
        color: #575757;
        font-size: 1rem;
        font-weight: 600;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding-left: 5%;
            padding-right: 5%;
        }
    }
    @media only screen and (max-width: 1050px){
        section h2{
            font-size: 2rem;
        }
        .sc-inner{
            height: 37rem;
            /* border: 1px solid green; */
        }
        .sci-left-bottom{
            margin-top: 0.6rem;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            min-height: unset;
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        /* section h2{
            font-size: 1.5rem;
        } */
        .sc-inner{
            height: auto;
        }
        .sci-left{
            width: 100%;
            padding-right: 0;
        }
        .sci-left-top{
            /* height: 37rem; */
            /* border: 1px solid green; */
        }
        .sci-left-bottom{
            width: 0;
            height: 0;
            overflow: hidden;
        }
        .sci-right{
            width: 0;
            margin-top: 0;
        }
    }
</style>