<template>
  <div class="home-header">
      <div class="hh-part hh-left">
          <p class="p-1">Event</p>
          <h1>Event Foodtraveller</h1>
          <p class="p-2">Anda mungkin telah mendengar pepatah “Kita adalah apa yang kita makan”. Makan mencerminkan pembuatan diri, aspek etis, politik, sosial, artistik, dan identitas.</p>
      </div>
      <div class="hh-part hh-right">
          <img src="../assets/imgth.png" alt="Masakan Nusantara">
      </div>
  </div>
</template>

<script>
export default {
    name: 'EventHeader'
}
</script>

<style scoped>
    .home-header{
        width: 100%;
        display: flex;
        height: 39rem;
    }
    .hh-part{
        width: 50%;
        height: 100%;
    }
    .hh-left{
        background: #1B4674;
        color: #ffffff;
        padding: 8.44rem;
        padding-left: 12.25rem;
        padding-right: 6.69rem;
        padding-bottom: 0;
    }
    .p-1{
        font-weight: 600;
        color: #75C6EF;
    }
    .hh-left h1{
        font-family: poppins;
        font-size: 3.25rem;
        width: 22.56rem;
        margin-top: 1.37rem;
    }
    .p-2{
        margin-top: 1.19rem;
    }
    .hh-right{
        background: #ffffff;
    }
    .hh-right img{
        height: 100%;
        width: 100%;
    }
</style>