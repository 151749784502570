<template>
  <!-- <div class="app">
    <MainNavbar /> -->
    <div class="content">
      <ContactHeader />
      <ContactForm />
      <ContactMaps />
    </div>
    <!-- <MainFooter />
  </div> -->
</template>

<script>
// @ is an alias to /src
// import MainNavbar from '@/components/MainNavbar.vue'
import ContactHeader from '@/components/contact-us/ContactHeader.vue'
import ContactForm from '@/components/contact-us/ContactForm.vue'
import ContactMaps from '@/components/contact-us/ContactMaps.vue'

// import MainFooter from '@/components/MainFooter.vue'

export default {
  name: 'ContactPage',
  components: {
    // MainNavbar,
    ContactHeader,
    ContactForm,
    ContactMaps
    // MainFooter
  }
}
</script>