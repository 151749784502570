<template>
  <!-- <div class="app">
    <MainNavbar /> -->
    <div class="content">
      <EventHeader />
    </div>
    <!-- <MainFooter />
  </div> -->
</template>

<script>
// @ is an alias to /src
// import MainNavbar from '@/components/MainNavbar.vue'
import EventHeader from '@/components/EventHeader.vue'
// import MainFooter from '@/components/MainFooter.vue'

export default {
  name: 'EventPage',
  components: {
    // MainNavbar,
    EventHeader
    // MainFooter
  }
}
</script>