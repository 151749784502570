<template>
  <!-- <div class="app">
    <MainNavbar /> -->
    <div class="content">
      <KulinerHeader />
      <KulinerKuliner />
    </div>
    <!-- <MainFooter />
  </div> -->
</template>

<script>
// @ is an alias to /src
// import MainNavbar from '@/components/MainNavbar.vue'
import KulinerHeader from '@/components/kuliner/KulinerHeader.vue'
import KulinerKuliner from '@/components/kuliner/KulinerKuliner.vue'

// import MainFooter from '@/components/MainFooter.vue'

export default {
  name: 'KulinerPage',
  components: {
    // MainNavbar,
    KulinerHeader,
    KulinerKuliner
    // MainFooter
  }
}
</script>
