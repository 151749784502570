import { defineStore } from "pinia";
import axiosClient from "../axios.js";

export const useGalleryStore = defineStore("gallery", {
  state: () => ({
    // ==================== state for home page
    homeGc1: null,
    homeGc2: null,
    homeGc3: null,
    // ==================== unused
    articles: null,
    articleNum: 1,
    articleDetail: null,
    articleDetailTitle: 'Article Detail',
    articleCategories: null
  }),
  getters: {
    // ==================== unused
    articlesTppTop: (state) => {
      return state.articlesTpp.slice(0, 2);
    },
  },
  actions: {
    // ==================== actions for home page
    async setHgc1(limit){
      const response = await axiosClient.get(`gallery/get-categories/` + limit);
      this.homeGc1 = response.data[0];
      this.homeGc2 = response.data[1];
      this.homeGc3 = response.data[2];
    },
    // ==================== unused
    setArticleNum(nm){
      this.articleNum = nm
    },
    async fetchArticles(limit) {
      const response = await axiosClient.get(`artikel/get-all/` + limit);
      this.articles = response.data;
    }
    //===============
  }
});