<template>
    <section>
        <div class="section-header">
            <h2>Meet The Foodies Team</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="section-content">
            <div class="cov-strip">
                <div class="strip"></div>
            </div>
            <div class="sc-inner" v-if="teammember">
                <div class="sci-part" v-for="(data, index) in teammember" :key="data">
                    <div class="sci-part-inner" @mouseenter="teamHover('enter', 'sdi-' + index);" @mouseleave="teamHover('leave', 'sdi-' + index);">
                        <div class="sci-part-image">
                            <img :src="imgsrc + data.image" alt="food traveller team"/>
                        </div>
                        <div class="sci-part-caption">
                            <h3>{{ data.name }}</h3>
                            <p>{{ data.position }}</p>
                            <div class="spc-description" v-if="data.description">
                                <div class="spc-description-inner" :id="'sdi-' + index">
                                    <div v-html="wordsLimit(data.description, 250)"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="sci-part">
                    <img src="../../assets/images/picture/imgat2.png" alt="food traveller team">
                    <div class="sci-part-caption">
                        <h3>Scoyt Alkin</h3>
                        <p>CTO Food Traveller</p>
                    </div>
                </div>
                <div class="sci-part">
                    <img src="../../assets/images/picture/imgat3.png" alt="food traveller team">
                    <div class="sci-part-caption">
                        <h3>David SImanjuntak</h3>
                        <p>COO Food Traveller</p>
                    </div>
                </div>
                <div class="sci-part">
                    <img src="../../assets/images/picture/imgat4.png" alt="food traveller team">
                    <div class="sci-part-caption">
                        <h3>Marina Haque</h3>
                        <p>Head of Finance</p>
                    </div>
                </div>
                <div class="sci-part">
                    <img src="../../assets/images/picture/imgat5.png" alt="food traveller team">
                    <div class="sci-part-caption">
                        <h3>Devina Atmaja</h3>
                        <p>Head of Marketing</p>
                    </div>
                </div>
                <div class="sci-part">
                    <img src="../../assets/images/picture/imgat6.png" alt="food traveller team">
                    <div class="sci-part-caption">
                        <h3>Dave Gultom</h3>
                        <p>Head of Mobile Developer</p>
                    </div>
                </div> -->
            </div>
        </div>
    </section>
</template>

<script setup>
    import { computed } from '@vue/reactivity';
    import { useTeamStore } from '@/stores/team';
    import { wordsLimit } from '@/functions/general';
    const teammember = computed(() => useTeamStore().dataShow);
    const imgsrc = process.env.VUE_APP_ROOT_API + 'images/team/';
    useTeamStore().setData();
    function teamHover(action, id){
        var i;
        const ec = document.getElementsByClassName('spc-description-inner');
        if(document.getElementById(id)){
            const el = document.getElementById(id);
            if(action == 'enter'){
                for(i = 0; i < ec.length; i++){
                    ec[i].classList.remove('sdi-active');
                }
                el.classList.add('sdi-active');
            }else{
                for(i = 0; i < ec.length; i++){
                    ec[i].classList.remove('sdi-active');
                }
            }
        }
    }
</script>

<style scoped>
    @-webkit-keyframes animateTeam {
		from {bottom:-1rem; opacity:0}
		to {bottom:0; opacity:1}
	}
	@keyframes animateTeam {
		from {bottom:-1rem; opacity:0}
		to {bottom:0; opacity:1}
	}
    section{
        width: 100%;
        min-height: 15rem;
        padding-top: 2.5rem;
        padding-left: 11.25rem;
        padding-right: 11.25rem;
        padding-bottom: 2.5rem;
        color: #1B4674;
        transition: 0.2s;
    }
    section h2{
        font-family: poppins;
        font-size: 2rem;
        font-weight: 700;
        margin: 0;
    }
    section p{
        margin-top: 1.6875rem;
        font-size: 1rem;
        font-weight: 400;
    }
    .section-header{
        text-align: center;
        padding-left: 30%;
        padding-right: 30%;
    }
    .section-content{
        width: 100%;
        height: 100%;
        margin-top: 2rem;
    }
    .cov-strip{
        display: flex;
        justify-content: center;
        padding-bottom: 2rem;
    }
    .strip{
        width: 5%;
        height: 0.5rem;
        background: #75C6EF;
    }
    .sc-inner{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        /* background: var(--smoke); */
    }
    .sci-part{
        position: relative;
        width: 33.33%;
        /* height: 370px; */
        overflow: hidden;
        text-align: center;
        padding: 1%;
        transition: 0.2s;
        /* border: 1px solid green; */
        /* background: var(--grey); */
    }
    .sci-part img{
        width: 100%;
        /* height: 100%; */
    }
    .sci-part-caption{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        text-align: left;
        color: #ffffff;
        padding: 1rem 2rem 2rem 2rem;
    }
    .sci-part-caption h3{
        font-family: poppins;
        font-size: 1.125rem;
        font-weight: 700;
    }
    .sci-part-caption p{
        margin: 0;
    }
    .spc-description{
        position: absolute;
        left: 1rem;
        right: 1rem;
        bottom: 45px;
        /* max-height: 100px; */
        /* width: 75%; */
        /* border: 1px solid green; */
        /* z-index: 15; */
    }
    .spc-description-inner{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        max-height: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        background: var(--white);
        color: var(--grey);
        /* border: 1px solid gold; */
        border-radius: 10px;
        padding: 0.5rem;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-size: 0.9rem;
        display: none;
        animation-name: animateTeam;
		animation-duration: 0.5s;
    }
    .sdi-active{
        display: block;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding-left: 4%;
            padding-right: 4%;
        }
        .section-header{
            padding-left: 0;
            padding-right: 0;
        }
    }
    @media only screen and (max-width: 1250px){
        .sc-inner{
            background: var(--grey);
        }
        .sci-part{
            /* height: 22rem; */
        }
    }
    @media only screen and (max-width: 1050px){
        .sci-part{
            /* height: 17rem; */
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding-left: 1.5%;
            padding-right: 1.5%;
        }
        .section-header{
            text-align: left;
        }
        .cov-strip{
            justify-content: left;
        }
        .strip{
            width: 15%;
        }
        .sc-inner{
            background: var(--grey);
        }
        .sci-part{
            width: 50%;
            /* height: 19rem; */
            margin: 0 0 2rem 0;
        }
    }
    @media only screen and (max-width: 450px){
        .sci-part{
            width: 100%;
            height: 22rem;
            padding-bottom: 2%;
        }
    }
</style>