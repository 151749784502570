import { defineStore } from "pinia";
import axiosClient from "../axios.js";

export const useArticleStore = defineStore("article", {
	state: () => ({
	// ==================== state for home page
	homeCulinaryInfo: null,
	homeCulinaryFood: null,
	homeHotel: null,
	homeWisata: null,
	homePijat: null,
	homeBs1: null,
	homeBs2: null,
	homeBs3: null,
	// ==================== state for kuliner page
	kulinerCfood: null,
	kulinerCinfo: null,
	// ==================== state for blog page
	blogData: null,
	// ==================== state for blog detail
	blogDetail: null,
	blogCategories: null,
	blogDetailRelated: null
	}),
	getters: {
	// 
	},
	actions: {
	// ==================== actions for home page
		async setHci(cg, limit){
			const response = await axiosClient.get(`artikel/kategori/`+ cg +`/` + limit);
			this.homeCulinaryInfo = response.data;
		},
		async setHcf(cg, limit){
			const response = await axiosClient.get(`artikel/kategori/`+ cg +`/` + limit);
			this.homeCulinaryFood = response.data;
		},
		async setHotel(cg, limit){
			const response = await axiosClient.get(`artikel/kategori/`+ cg +`/` + limit);
			this.homeHotel = response.data;
		},
		async setWisata(cg, limit){
			const response = await axiosClient.get(`artikel/kategori/`+ cg +`/` + limit);
			this.homeWisata = response.data;
		},
		async setPijat(cg, limit){
			const response = await axiosClient.get(`artikel/kategori/`+ cg +`/` + limit);
			this.homePijat = response.data;
		},
		async setHomeBlog(){
			const response = await axiosClient.get(`artikel/blog-snippet`);
			this.homeBs1 = response.data[0].data;
			this.homeBs2 = response.data[1].data;
			this.homeBs3 = response.data[2].data;
		},
		// ==================== actions for kuliner page
		async setKcfood(cg, limit){
			const response = await axiosClient.get(`artikel/kategori/`+ cg +`/` + limit);
			this.kulinerCfood = response.data.content;
		},
		async setKcinfo(cg, limit){
			const response = await axiosClient.get(`artikel/kategori/`+ cg +`/` + limit);
			this.kulinerCinfo = response.data.content;
		},
		// ==================== actions for blog page
		async setBlogData(){
			this.blogData = null;
			const response = await axiosClient.get(`artikel/blog-page`);
			this.blogData = response.data;
			// console.log(this.blogData);
		},
		// ==================== action for blog detail
		async setBlogDetail(id){
			this.blogDetail = null;
			const response = await axiosClient.get(`artikel/detail/` + id);
			this.blogDetail = response.data;
		},
		async setBlogCategories(){
			this.blogDetail = null;
			const response = await axiosClient.get(`artikel/kategori/get-all`);
			this.blogCategories = response.data;
			console.log(this.blogCategories);
		},
		async setRelated(id, limit){
			const response = await axiosClient.get(`artikel/related/` + id + `/` + limit);
			this.blogDetailRelated = response.data;
		}
	}
});