import { defineStore } from "pinia";
import axiosClient from "../axios.js";

export const useRecipeStore = defineStore("recipe", {
  state: () => ({
    // ==================== state for resep page
    resepMakanan: null,
    resepMinuman: null,
    resepMakananKhas: null,
    resepCemilan: null,
    // ==================== state for resep detail page
    resepDetail: null,
    // ==================== unused
    articles: null,
    articleNum: 1,
    articleDetail: null,
    articleDetailTitle: 'Article Detail',
    articleCategories: null
  }),
  getters: {
    articlesTppTop: (state) => {
      return state.articlesTpp.slice(0, 2);
    },
  },
  actions: {
    // ==================== actions for resep page
    async setRm(cg, limit){
      const response = await axiosClient.get(`resep/kategori/` + cg +`/` + limit);
      this.resepMakanan = response.data;
    },
    async setRmi(cg, limit){
      const response = await axiosClient.get(`resep/kategori/` + cg +`/` + limit);
      this.resepMinuman = response.data;
    },
    async setRmkhas(cg, limit){
      const response = await axiosClient.get(`resep/kategori/` + cg +`/` + limit);
      this.resepMakananKhas = response.data;
    },
    async setRcem(cg, limit){
      const response = await axiosClient.get(`resep/kategori/` + cg +`/` + limit);
      this.resepCemilan = response.data;
    },
    // ==================== actions for resep detail page
    async setResepDetail(id){
      this.resepDetail = null;
      const response = await axiosClient.get(`resep/detail/` + id);
      this.resepDetail = response.data;
    },
    // =======================================
    setArticleNum(nm){
      this.articleNum = nm
    },
    async fetchArticles(limit) {
      const response = await axiosClient.get(`artikel/get-all/` + limit);
      this.articles = response.data;
    },
    async fetchArticleDetail(id) {
      this.articleDetail = false;
      const response = await axiosClient.get(`artikel/detail/` + id);
      this.articleDetail = response.data;
      // this.articleDetailTitle = response.data[0].title;
    },
    async fetchArticleCategories() {
      const response = await axiosClient.get(`artikel/get-categories`);
      this.articleCategories = response.data;
    },
    async fetchArticlesMix(limit) {
      const response = await axiosClient.get(`artikel/get-all/` + limit);
      this.articlesMix = response.data;
    },
    async fetchArticleBtBig(limit) {
      const response = await axiosClient.get(`artikel/get-all/` + limit);
      this.articleBtBig = response.data;
    },
    async fetchArticleBtRight(limit) {
      const response = await axiosClient.get(`artikel/get-all/` + limit);
      this.articleBtRight = response.data;
    },
    //===============
  }
});