import { defineStore } from "pinia";
import axiosClient from "../axios.js";

export const useTeamStore = defineStore("team", {
    state: () => ({
        // ==================== states used on team module and its relation
        allData: null,
        articles: null,
        searchBar: {select: {title: 'Semua', value: 'all'}, input: {value: null}},
        categories: null,
        activeCategory: null,
        dataShow: null,
        dataDetail: null,
        dataByCategory: null,
        abcShow: null,
        limit: 9
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on team module and its relation
        async setData(){
            if(!this.allData){
                try{
                    const response = await axiosClient.get(`team/get-all`);
                    // const cgres = await axiosClient.get(`artikel/kategori/get-all`);
                    this.allData = response.data;
                    this.articles = response.data;
                    // this.categories = cgres.data;
                    this.showArticles(this.limit);
                    console.log(this.allData);
                    console.log(this.categories);
                }catch(error){
                    console.log(error);
                }
            }
        },
        async setDataDetail(slug){
            if(!this.allData){
                await this.setData();
            }
            this.dataDetail = null;
            try{
                const response = await axiosClient.get(`article/` + slug);
                this.dataDetail = response.data.articles;
                // console.log(this.dataDetail);
            }catch(error){
                console.log(error);
            }
        },
        async setDataByCategory(slug){
            if(!this.allData){
                await this.setData();
            }
            var at = [];
            for(var i = 0; i < this.categories.length; i++){
                if(this.categories[i].slug == slug){
                    this.activeCategory = this.categories[i].title;
                }
            }
            for(i = 0; i < this.allData.length; i++){
                if(this.allData[i].category.slug == slug){
                    at.push(this.allData[i]);
                }
            }
            this.dataByCategory = at;
            this.showAbc(this.limit);
        },
        showArticles(limit){
            this.dataShow = this.articles.slice(0, limit);
        },
        moreArticles(){
            const ln = this.dataShow.length;
            this.showArticles((ln + this.limit));
        },
        searchbarSelect(category, slug){
            this.searchBar.select.title = category;
            this.searchBar.select.value = slug;
            this.searchService();
        },
        searchbarInput(value){
            this.searchBar.input.value = value;
            this.searchService();
        },
        searchService(){
            var newsd = [];
            if(this.searchBar.select.value != 'all'){ //if selected category
                for(var i = 0; i < this.allData.length; i++){
                    if(this.allData[i].category){
                        if(this.allData[i].category.title == this.searchBar.select.title){
                            newsd.push(this.allData[i]);
                        }
                    }
                }
            }else{
                newsd = this.allData;
            }
            if(this.searchBar.input.value){
                var result = [];
                var inval = new RegExp(this.searchBar.input.value, 'gi');
                for(i = 0; i < newsd.length; i++){
                    if(newsd[i].title.match(inval)){
                        result.push(newsd[i]);
                    }
                }
                this.articles = result;
            }else{
                this.articles = newsd;
            }
            this.showArticles(this.limit);
        },
        showAbc(limit){
            this.abcShow = this.dataByCategory.slice(0, limit);
        },
        moreAbc(){
            const ln = this.abcShow.length;
            this.showAbc((ln + this.limit));
        }
    }
});