import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import AboutPage from '../views/AboutPage.vue';
import ResepPage from '../views/ResepPage.vue';
import ResepDetailPage from '../views/ResepDetailPage.vue';
import KulinerPage from '../views/KulinerPage.vue';
import TravelPage from '../views/TravelPage.vue';
import KamusRempahPage from '../views/KamusRempahPage.vue';
import KamusPanganCategoryView from '../views/KamusPanganCategoryView.vue';
import KamusRempahDetailPage from '../views/KamusRempahDetailPage.vue';
import BlogPage from '../views/BlogPage.vue';
import BlogDetailPage from '../views/BlogDetailPage.vue';
import BlogCategory from '../views/BlogCategory.vue';
import GalleryPage from '../views/GalleryPage.vue';
import EventPage from '../views/EventPage.vue';
import ContactPage from '../views/ContactPage.vue';
import NotFoundPage from '../views/NotFoundPage.vue';

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/about',
    name: 'AboutPage',
    component: AboutPage
  },
  {
    path: '/resep',
    name: 'ResepPage',
    component: ResepPage
  },
  {
    path: '/resep/detail/::id',
    name: 'ResepDetailPage',
    component: ResepDetailPage
  },
  {
    path: '/kuliner',
    name: 'KulinerPage',
    component: KulinerPage
  },
  {
    path: '/travel',
    name: 'TravelPage',
    component: TravelPage
  },
  {
    path: '/kamus-pangan',
    name: 'KamusRempahPage',
    component: KamusRempahPage
  },
  {
    path: '/kamus-pangan/category/:slug',
    name: 'KamusPanganCategory',
    component: KamusPanganCategoryView
  },
  {
    path: '/kamus-pangan/detail/:id',
    name: 'KamusRempahDetailPage',
    component: KamusRempahDetailPage
  },
  {
    path: '/blog',
    name: 'BlogPage',
    component: BlogPage
  },
  {
    path: '/blog/detail/:id',
    name: 'BlogDetailPage',
    component: BlogDetailPage
  },
  {
    path: '/blog/categories',
    name: 'BlogCategories',
    component: BlogDetailPage
  },
  {
    path: '/blog/category/:slug',
    name: 'BlogCategory',
    component: BlogCategory
  },
  {
    path: '/gallery',
    name: 'GalleryPage',
    component: GalleryPage
  },
  {
    path: '/events',
    name: 'EventPage',
    component: EventPage
  },
  {
    path: '/contact-us',
    name: 'ContactPage',
    component: ContactPage
  },
  {
    path: '/not-found',
    name: 'NotFoundPage',
    component: NotFoundPage
  },
  {
    path: '/:catchAll(.*)',
    // redirect: '/not-found',
    beforeEnter(){
      location.href = process.env.BASE_URL + 'not-found'
    }
  }
  // ,
  // {
    // path: '/about-view',
    // name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutPage.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
