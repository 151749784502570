import { useRempahStore } from "@/stores/rempah";
export function searchbarInput(id){
    useRempahStore().searchbarInput(document.getElementById(id).value);
}










