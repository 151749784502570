import { useBlogStore } from "@/stores/blog";
export function searchbarInput(id){
    useBlogStore().searchbarInput(document.getElementById(id).value);
}










