<template>
    <nav class="navbar">
        <div class="nav-pad">
            <div class="navbar-inner">
                <div class="navbar-logo">
                    <router-link to="/" @click="pageScrollTop">
                        <img src="./assets/logo.png" alt="food traveller logo">
                    </router-link>
                </div>
                <div class="navbar-menu" id="navbar-menu">
                    <div class="nm-inner">
                        <div class="nm-part">
                            <router-link to="/" class="nm-part-link" @click="menuToggle">Home</router-link>
                        </div>
                        <div class="nm-part">
                            <router-link to="/about" class="nm-part-link" @click="menuToggle" >About</router-link>
                        </div>
                        <div class="nm-part">
                            <router-link to="/resep" class="nm-part-link" @click="menuToggle" >Resep</router-link>
                        </div>
                        <div class="nm-part">
                            <router-link to="/kuliner" class="nm-part-link" @click="menuToggle" >Kuliner</router-link>
                        </div>
                        <!-- <div class="nm-part">
                            <router-link to="/travel" class="nm-part-link" @click="menuToggle" >Travel</router-link>
                        </div> -->
                        <div class="nm-part">
                            <router-link to="/kamus-pangan" class="nm-part-link" @click="menuToggle" >Kamus Pangan</router-link>
                        </div>
                        <div class="nm-part">
                            <router-link to="/blog" class="nm-part-link" @click="menuToggle" >Blog</router-link>
                        </div>
                        <div class="nm-part">
                            <router-link to="/gallery" class="nm-part-link" @click="menuToggle" >Gallery</router-link>
                        </div>
                        <!-- <div class="nm-part">
                            <router-link to="/events" class="nm-part-link" @click="menuToggle" >Events</router-link>
                        </div> -->
                        <div class="nm-part nm-mobile">
                            <router-link to="/contact-us" class="nm-part-link" @click="menuToggle" >Contact Us</router-link>
                        </div>
                    </div>
                    <div class="nm-mobile-close" @click="menuHide">
                        <img src="./assets/images/icons/mbclose.png" alt="p3k melinda" />
                    </div>
                </div>
                <div class="navbar-menu-bg" id="navbar-menu-bg" @click="menuHide"></div>
                <div class="navbar-button">
                    <div class="nb-contact">
                        <router-link to="/contact-us" @click="pageScrollTop" >Contact Us</router-link>
                    </div>
                    <div class="nb-menumob">
                        <img src="./assets/images/icons/mobmenu.png" alt="p3k melinda" @click="menuShow" />
                    </div>
                </div>
            </div>
        </div>
    </nav>
    <router-view/>
    <footer>
        <div class="footer-top">
            <div class="staff">
                <div class="staff-icon">
                    <img src="./assets/images/icons/cb-phone.png" alt="food traveller phone" class="staff-icon-phone" />
                    <img src="./assets/images/icons/male-staff.png" alt="food traveller staff" class="staff-icon-male" />
                </div>
                <div class="staff-text">
                    <p>Please feel free to contact our friendly reception staff with any medical enquiry, or call (022) 4233777</p>
                </div>
            </div>
            <div class="socials">
                <a href="https://www.facebook.com">
                <div class="socials-icon">
                    <img src="./assets/images/icons/cc-facebook.png" alt="food traveller socials">
                </div>
                </a>
                <a href="https://www.twitter.com">
                <div class="socials-icon">
                    <img src="./assets/images/icons/cc-twitter.png" alt="food traveller socials">
                </div>
                </a>
                <a href="https://www.linkedin.com">
                <div class="socials-icon">
                    <img src="./assets/images/icons/cc-linkedin.png" alt="food traveller socials">
                </div>
                </a>
                <a href="https://www.instagram.com">
                <div class="socials-icon">
                    <img src="./assets/images/icons/cc-instagram.png" alt="food traveller socials">
                </div>
                </a>
            </div>
        </div>
        <div class="footer-middle">
            <div class="fm-logo">
                <img src="./assets/logo.png" alt="food traveller logo">
            </div>
            <div class="fm-text">
                <div class="fm-office">
                    <h2>Office</h2>
                    <p>Jl. Dr. Cipto no 1, Bandung. Jawa Barat - Indonesia</p>
                </div>
                <div class="fm-contact">
                    <h2>Contact</h2>
                    <p>Hunting  : (022) 4233777 Pendaftaran : 08157100888 (Call & WhatsApp) UGD       : (022) 4232777</p>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="fb-left">
                <p>© 2022 RS Melinda 2, All Rights Reserved.</p>
            </div>
            <div class="fb-right">
                <div class="fbr-medals">
                    <img src="./assets/images/icons/medals.png" alt="food traveller medals">
                </div>
                <div class="fbr-text">
                    <router-link to="/">Privacy Policy</router-link>
                    <router-link to="/" class="cookies-link">Cookies</router-link>
                </div>
            </div>
        </div>
        <div class="go-top" @click="pageScrollTop">
            <img src="./assets/images/icons/arrow-1.png" alt="Food Traveller" />
        </div>
    </footer>
</template>
<script>
    export default {
        methods: {
            menuToggle(){
                const nm = document.getElementById('navbar-menu');
                const nb = document.getElementById('navbar-menu-bg');
                if(screen.width <= 750){
                    if(nm.style.left == '26%'){
                        nm.style.left = '110%';
                        nb.style.width = '0';
                    }else{
                        nm.style.left = '26%';
                        nb.style.width = '100%';
                    }
                }
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            },
            menuShow(){
              document.getElementById('navbar-menu').style.left = '26%';
              document.getElementById('navbar-menu-bg').style.width = '100%';
            },
            menuHide(){
              document.getElementById('navbar-menu').style.left = '110%';
              document.getElementById('navbar-menu-bg').style.width = '0';
            },
            pageScrollTop(){
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            }
        }
    }
</script>

<style>
    @font-face {
        font-family: 'roboto';
        src: url('assets/fonts/roboto/Roboto-Regular.ttf') format('truetype');
        font-display: swap;
    }
    @font-face {
        font-family: 'poppins';
        src: url('assets/fonts/poppins/Poppins-SemiBold.ttf') format('truetype');
        font-display: swap;
        font-weight: bold;
    }
    :root {
		--primary: #75C6EF;
		--primary-hover: #65b7e0;
		--white: #ffffff;
		--grey: #575757;
		--softgrey: lightgrey;
		--smoke: whitesmoke;
		--navy: #1B4674;
		--red: #CD4051;
		--cream: #FCE8CE;
		--cream-bg: #FEF8F0;
		--softcream: #F5F4F4;
	}
    *{
        box-sizing: border-box;
        /* margin: 0; */
    }
    html{
        scroll-behavior: smooth;
        display: flex;
        justify-content: center;
        font-size: 16px;
    }
    body{
        width: 100%;
        margin: 0;
        font-family: roboto;
        font-size: 1rem;
        min-width: 250px;
        max-width: 2500px; /* same as .nav-pad */
    }
    ::-webkit-scrollbar{
        width: 0.5rem;
    }
    ::-webkit-scrollbar-thumb{
        background: #75C6EF;
    }
    .button-main a{
        background: #FCE8CE;
        color: #575757;
        padding: 0.75rem;
        padding-left: 2rem;
        padding-right: 2rem;
        border-radius: 2rem;
        text-decoration: none;
    }
    .content{
        margin: auto;
        padding-top: 4.81rem;
        min-height: 1000px;
    }
    @media print {
        .no-print{
            display: none;
        }
    }
    @keyframes pulse-bg{
        0%{
            background-color: #eee;
        }
        50%{
            background-color: #e0e0e0;
        }
        100%{
            background-color: #eee;
        }
    }
    @keyframes pulse-bg-white{
        0%{
            background-color: #eee;
        }
        50%{
            background-color: #ffffff;
        }
        100%{
            background-color: #eee;
        }
    }
    @keyframes pulse-bg-navy{
        0%{
            background-color: #a1c1e2;
        }
        50%{
            background-color: #1B4674;
        }
        100%{
            background-color: #a1c1e2;
        }
    }
    @keyframes pulse-bg-bluesky{
        0%{
            background-color: #cceeff;
        }
        50%{
            background-color: #75C6EF;
        }
        100%{
            background-color: #cceeff;
        }
    }
    .desktop{
        display: inline;
    }
    .mobile{
        display: none;
    }
    @media only screen and (max-width: 750px){
        .desktop{
            display: none;
        }
        .mobile{
            display: inline;
        }
    }
    /* navbar */
    .navbar{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 4.81rem;
        display: flex;
        border: 1px solid whitesmoke;
        font-family: roboto;
        font-weight: 600;
        background: #ffffff;
        z-index: 15;
        white-space: nowrap;
        user-select: none;
        transition: 0.2s;
        justify-content: center;
    }
    .nav-pad{
        width: 100%;
        max-width: 2500px; /* same as body */
        padding-top: 0.5rem;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        transition: 0.2s;
        /* border: 1px solid green; */
    }
    .navbar-inner{
        display: flex;
        flex-wrap: nowrap;
        text-align: center;
        /* width: 100%; */
    }
    .navbar-logo{
        width: 36%;
        text-align: left;
        transition: 0.2s;
    }
    .navbar-menu{
        width: 49%;
        padding-top: 0.7rem;
        transition: 0.2s;
        overflow: hidden;
        background: #ffffff;
        z-index: 15;
    }
    .navbar-menu-bg{
        position: fixed;
        top: 0;
        left: 110%;
        width: 0;
        height: 150%;
        background: #000000;
        opacity: 0.5;
        z-index: 10;
    }
    .nm-inner{
        display: flex;
        flex-wrap: nowrap;
        background: #ffffff;
    }
    .nm-part{
        padding: 0.5rem;
        width: auto;
        margin: auto;
    }
    .nm-part-link{
        text-decoration: none;
        color: #575757;
        transition: 0.2s;
    }
    .nm-part-link:hover{
        color: #75C6EF;
    }
    .router-link-active{
        color: #75C6EF;
    }
    .nm-mobile{
        display: none;
    }
    .nm-mobile-close{
        display: none;
    }
    .navbar-button{
        width: 15%;
        text-align: right;
        padding: 1.2rem;
        padding-right: 0;
        transition: 0.2s;
        overflow: hidden;
    }
    .navbar-button a{
        background: #FCE8CE;
        color: #575757;
        padding: 0.75rem;
        padding-left: 2rem;
        padding-right: 2rem;
        border-radius: 2rem;
        text-decoration: none;
    }
    .nb-menumob{
        display: none;
        z-index: 100;
    }
    .nb-menumob img{
        max-width: 2rem;
    }
    @media only screen and (max-width: 1500px){
        .nav-pad{
            padding-left: 5%;
            padding-right: 5%;
        }
        .navbar-logo{
            width: 23%;
        }
        .navbar-logo img{
            height: 75%;
        }
        .navbar-menu{
            width: 59%;
        }
        .navbar-button{
            width: 21%;
        }
    }
    @media only screen and (max-width: 1050px){
        html{
            display: inline;
        }
        .navbar{
            font-size: 75%;
        }
        .navbar-button a{
            padding: 0.5rem;
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
    @media only screen and (max-width: 750px){
        .nav-pad{
            padding-top: 0.75rem;
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .navbar-menu{
            position: fixed;
            top: 0;
            left: 110%;
            width: 75%;
            height: 150%;
            transition: 0.2s;
            text-align: left;
            padding-left: 1%;
            padding-right: 15%;
            font-size: 1rem;
        }
        .nm-mobile-close{
            display: block;
            position: absolute;
            top: 1rem;
            right: 4.1%;
            width: 1.5rem;
            height: 1.5rem;
        }
        .nm-mobile-close img{
            width: 100%;
            max-width: 1.5rem;
            height: 100%;
        }
        .navbar-menu-bg{
            left: 0;
        }
        .nm-inner{
            display: inline;
        }
        .nm-mobile{
            display: block;
        }
        .nm-mobile img{
            width: 1.5rem;
        }
        .navbar-button{
            width: 77%;
            padding: 1rem;
            padding-top: 0.5rem;
            padding-right: 0;
        }
        .nb-contact{
            display: none;
        }
        .nb-menumob{
            display: block;
        }
    }
    /* footer */
    footer{
        position: relative;
        background: #ffffff;
    }
    .footer-top{
        display: flex;
        flex-wrap: wrap;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        background: #FEF8F0;
        height: 10.25rem;
        transition: 0.2s;
    }
    .staff{
        width: 60%;
        display: flex;
        padding-top: 3.87rem;
        transition: 0.2s;
    }
    .staff-icon{
        position: relative;
        min-width: 100px;
        width: 19%;
        max-width: 110px;
        min-height: 75px;
    }
    .staff-icon img{
        position: absolute;
        width: 55%;
    }
    .staff-icon-phone{
        top: 0;
        left: 0;
        z-index: 1;
    }
    .staff-icon-male{
        top: 0;
        right: 0;
        z-index: 0;
    }
    .staff-text{
        font-weight: 600;
        padding-left: 3.6rem;
        color: #1B4674;
        transition: 0.2s;
    }
    .staff-text p{
        margin: 0;
    }
    .socials{
        width: 40%;
        display: flex;
        justify-content: right;
        padding-top: 3.87rem;
        transition: 0.2s;
    }
    .socials-icon{
        margin: 0.5rem;
        height: 1.9rem;
    }
    .socials-icon img{
        height: 100%;
    }
    .footer-middle{
        display: flex;
        flex-wrap: wrap;
        height: 14.69rem;
        width: 100%;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        transition: 0.2s;
    }
    .fm-logo{
        width: 40%;
        padding-top: 5.94rem;
        transition: 0.2s;
    }
    .fm-text{
        display: flex;
        flex-wrap: wrap;
        width: 60%;
        padding-top: 5.37rem;
        color: #1C4677;
        transition: 0.2s;
    }
    .fm-office{
        width: 50%;
        padding-left: 1rem;
        padding-right: 1rem;
        transition: 0.2s;
    }
    .fm-contact{
        width: 50%;
        transition: 0.2s;
    }
    .fm-text h2{
        font-size: 1.125rem;
        font-weight: 600;
        margin: 0;
        margin-bottom: 1rem;
    }
    .fm-text p{
        margin: 0;
        margin-top: 0.2rem;
        font-weight: 400;
        font-size: 1rem;
        transition: 0.2s;
    }
    .footer-bottom{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 8.0625rem;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        background-image: url('./assets/images/background/ftbt-background.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        transition: 0.2s;
    }
    .footer-bottom p{
        font-size: 0.9rem;
        transition: 0.2s;
    }
    .fb-left{
        width: 50%;
        padding-top: 1.25rem;
        color: #1C4677;
        transition: 0.2s;
    }
    .fb-right{
        position: relative;
        display: flex;
        justify-content: center;
        width: 50%;
        transition: 0.2s;
    }
    .fbr-medals{
        margin-top: 2.0625rem;
    }
    .fbr-text{
        margin-top: 2.5rem;
        margin-left: 1.3125rem;
        transition: 0.2s;
        font-size: 0.9rem;
    }
    .fbr-text a{
        text-decoration: none;
        color: #1C4677;
    }
    .cookies-link{
        margin-left: 1rem;
    }
    .go-top{
        display: flex;
        position: fixed;
        right: 1%;
        bottom: -10%;
        width: 75px;
        height: 75px;
        background: #a1a2a359;
        color: #515253;
        justify-content: center;
        align-items: center;
        z-index: 15;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.2s;
    }
    .go-top img{
        transform: rotate(180deg);
    }
    @media only screen and (max-width: 1650px){
        .fm-logo{
            width: 26%;
        }
        .fm-text{
            width: 74%;
        }
    }
    @media only screen and (max-width: 1500px){
        .footer-top{
            padding-left: 5%;
            padding-right: 5%;
        }
        .footer-middle{
            padding-left: 5%;
            padding-right: 5%;
        }
        .footer-bottom{
            padding-left: 5%;
            padding-right: 5%;
        }
        .go-top{
            width: 50px;
            height: 50px;
            right: 1%;
        }
    }
    @media only screen and (max-width: 1015px){
        .footer-top{
            height: auto;
        }
        .staff{
            width: 100%;
        }
        .socials{
            width: 100%;
            justify-content: left;
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    }
    @media only screen and (max-width: 750px){
        .staff{
            padding-top: 2.5rem;
        }
        .staff-text{
            padding-left: 5%;
        }
        .footer-top{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .footer-middle{
            height: auto;
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .fm-logo{
            width: 100%;
            padding-top: 2rem;
        }
        .fm-text{
            width: 100%;
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
        .fm-text p{
            font-size: 0.9rem;
        }
        .fm-office{
            width: 100%;
            padding-left: 0;
        }
        .fm-contact{
            width: 100%;
            margin-top: 2rem;
        }
        .footer-bottom{
            height: 10rem;
            width: 100%;
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .footer-bottom p{
            font-size: 0.75rem;
        }
        .fb-left{
            width: 100%;
            padding-top: 2rem;
        }
        .fb-left p{
            margin: 0;
        }
        .fb-right{
            width: 100%;
            justify-content: left;
        }
        .fbr-text{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            margin-top: 0;
            margin-left: 0;
            font-size: 0.75rem;
        }
        .go-top{
            right: 2.5%;
            border-radius: 50px;
        }
    }
</style>