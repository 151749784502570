<template>
  <div class="home-header">
      <div class="hh-part hh-left">
          <p class="p-1">Blog</p>
          <h1>Rempah Tradisional Indonesia</h1>
          <p class="p-2">Anda mungkin telah mendengar pepatah “Kita adalah apa yang kita makan”. Makan mencerminkan pembuatan diri, aspek etis, politik, sosial, artistik, dan identitas.</p>
      </div>
      <div class="hh-part hh-right">
          <img src="../../assets/imgbh.png" alt="Masakan Nusantara">
      </div>
  </div>
</template>

<script>
export default {
    name: 'BlogHeader'
}
</script>

<style scoped>
    .home-header{
        width: 100%;
        display: flex;
        height: 39rem;
        transition: 0.2s;
    }
    .hh-part{
        width: 50%;
        height: 100%;
        transition: 0.2s;
    }
    .hh-left{
        background: #1B4674;
        color: #ffffff;
        padding: 8.44rem;
        padding-left: 12.25rem;
        padding-right: 6.69rem;
        padding-bottom: 0;
        transition: 0.2s;
    }
    .p-1{
        font-weight: 600;
        color: #75C6EF;
    }
    .hh-left h1{
        font-family: poppins;
        font-size: 3.25rem;
        width: 22.56rem;
        margin-top: 1.37rem;
        transition: 0.2s;
    }
    .p-2{
        margin-top: 1.19rem;
    }
    .hh-right{
        background: #ffffff;
        transition: 0.2s;
    }
    .hh-right img{
        height: 100%;
        width: 100%;
    }
    @media only screen and (max-width: 1500px){
        .home-header{
            height: 29rem;
        }
        .hh-left{
            padding-left: 5%;
            padding-top: 2rem;
        }
        .hh-left h1{
            font-size: 2.6rem;
        }
    }
    @media only screen and (max-width: 1050px){
        .home-header{
            height: 26rem;
        }
        .hh-left{
            padding-top: 1rem;
        }
    }
    @media only screen and (max-width: 750px){
        .home-header{
            height: auto;
        }
        .hh-part{
            width: 100%;
        }
        .hh-left{
            width: 100%;
            height: 100%;
            padding-left: 2.5%;
            padding-right: 2.5%;
            padding-bottom: 2rem;
        }
        .hh-left h1{
            font-size: 2rem;
            width: auto;
        }
        .hh-right{
            width: 0;
        }
    }
</style>