<template>
    <section>
        <div class="section-header">
            <h2>Culinary Latest</h2>
            <p>Ingin mencicipi makanan dan minuman yang sudah ada sejak masa penjajahan Belanda dan Jepang? Tempat makan yang sudah berdiri puluhan tahun dan cita rasa makanannya tidak berubah? Atau ingin tau tempat makan paling hits yang ada di kota Bandung? Cek info tempat kuliner terbaru, terpopular, hingga promosinya di sini.</p>
        </div>
        <div class="section-tab">
            <div class="tab-head">
                <div class="tab-button tb-active" id="button-info" @click="selectTab('info', 'button-info')">Culinary Info</div>
                <div class="tab-button" id="button-food" @click="selectTab('food', 'button-food')">Culinary Food</div>
            </div>
            <div class="tab-body">
                <!-- {{ culinfo }} -->
                <div class="tab-content tc-first" id="info">
                    <ArticleCardSkeleton v-if="!culinfo"/>
                    <div v-else>
                        <div class="tc-inner">
                            <div class="tci-part" v-for="(article) in culinfo.content" :key="article.id">
                                <div class="tcp-img">
                                    <img v-bind:src="imgsrc + article.picture" alt="food traveller" />
                                    <div class="sci-gradient"></div>
                                </div>
                                <div class="tci-part-caption">
                                    <div class="tci-part-label">{{ article.category_name }}</div>
                                    <div class="tci-part-captext">
                                        <router-link :to="'/blog/detail/' + article.id">
                                            <h3>{{ article.title }}</h3>
                                        </router-link>
                                        <p>{{ article.meta_description }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="tci-part mobile" v-if="culinfo.count > limit">
                                <div class="tcp-more">
                                    <router-link to="/blog">More food articles</router-link>
                                </div>
                            </div>
                        </div>
                        <br class="desktop" v-if="culinfo.count > limit"/>
                        <div class="tab-footer desktop" v-if="culinfo.count > limit">
                            <router-link to="/blog">More food articles</router-link>
                        </div>
                    </div>
                </div>
                <div class="tab-content" id="food">
                    <ArticleCardSkeleton v-if="!foodinfo"/>
                    <div v-else>
                        <div class="tc-inner">
                            <div class="tci-part" v-for="(article) in foodinfo.content" :key="article.id">
                                <div class="tcp-img">
                                    <img v-bind:src="imgsrc + article.picture" alt="food traveller" />
                                    <div class="sci-gradient"></div>
                                </div>
                                <div class="tci-part-caption">
                                    <div class="tci-part-label">{{ article.category_name }}</div>
                                    <div class="tci-part-captext">
                                        <router-link :to="'/blog/detail/' + article.id">
                                            <h3>{{ article.title }}</h3>
                                        </router-link>
                                        <p>{{ article.meta_description }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="tci-part mobile" v-if="foodinfo.count > limit">
                                <div class="tcp-more">
                                    <router-link to="/blog">More food articles</router-link>
                                </div>
                            </div>
                        </div>
                        <br class="desktop" v-if="culinfo.count > limit"/>
                        <div class="tab-footer desktop" v-if="foodinfo.count > limit">
                            <router-link to="/blog">More food articles</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { computed } from "@vue/runtime-core";
    import { useArticleStore } from "../../stores/article";
    import ArticleCardSkeleton from "../skeletons/ArticleCardSkeleton.vue";
    const limit = 3;
    const culinfo = computed(() => useArticleStore().homeCulinaryInfo);
    const foodinfo = computed(() => useArticleStore().homeCulinaryFood);
    useArticleStore().setHci(6, limit); // (article_categories.id, limit)
    useArticleStore().setHcf(2, limit); // (article_categories.id, limit)
    function selectTab(a, b){
        var tb = document.getElementsByClassName('tab-button');
        var tc = document.getElementsByClassName('tab-content');
        var i;
        for(i = 0; i < tc.length; i++){
            tc[i].style.display = 'none';
            tb[i].classList.remove('tb-active');
        }
        document.getElementById(a).style.display = 'block';
        document.getElementById(b).classList.add('tb-active');
    }
    const imgsrc = process.env.VUE_APP_ROOT_API + 'images/articles/';
</script>

<style scoped>
    section{
        width: 100%;
        /* min-height: 50rem; */
        padding-top: 5rem;
        padding-left: 11.25rem;
        padding-right: 11.25rem;
        padding-bottom: 2.5rem;
        color: #1B4674;
        transition: 0.2s;
    }
    .section-header{
        padding-left: 1rem;
        padding-right: 1rem;
        transition: 0.2s;
    }
    section h2{
        font-family: poppins;
        font-size: 2.625rem;
        font-weight: 700;
        margin: 0;
        line-height: 1;
        transition: 0.2s;
    }
    section p{
        margin-top: 1.6875rem;
        font-size: 1.5rem;
        margin: 0;
        margin-top: 1rem;
        margin-bottom: 1rem;
        transition: 0.2s;
    }
    .section-tab{
        width: 100%;
        margin-top: 2.5rem;
    }
    .tab-head{
        display: flex;
        flex-wrap: nowrap;
        padding-left: 1rem;
        padding-right: 1rem;
        transition: 0.2s;
        overflow: auto;
    }
    .tab-head::-webkit-scrollbar{
        display: none;
    }
    .tab-button{
        cursor: pointer;
        margin-right: 1rem;
        font-family: poppins;
        font-size: 1.25rem;
        font-weight: 700;
        color: #bbbbbb;
        padding-bottom: 1rem;
        user-select: none;
        white-space: nowrap;
    }
    .tb-active{
        color: #1C4576;
        border-bottom: 5px solid #75C6EF;
    }
    .tab-body{
        width: 100%;
        min-height: 24rem;
        transition: 0.2s;
    }
    .tab-content{
        display: none;
    }
    .tc-first{
        display: block;
    }
    .tc-inner{
        display: flex;
        padding-top: 2rem;
        transition: 0.2s;
        /* margin-bottom: 2rem; */
        /* border: 1px solid green; */
    }
    .tc-inner::-webkit-scrollbar{
        display: none;
    }
    .tci-part{
        position: relative;
        height: 24rem;
        min-width: 15rem;
        width: 33.33%;
        padding-left: 1%;
        padding-right: 1%;
        transition: 0.2s;
    }
    .tcp-img{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .tcp-img img{
        width: 100%;
        height: 100%;
        border-radius: 10px;
        transition: 0.2s;
    }
    .sci-gradient{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 45%;
        background-image: linear-gradient(transparent, #000000);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .tci-part-caption{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .tci-part-label{
        position: absolute;
        top: 5%;
        left: 5%;
        background: #75C6EF;
        color: #ffffff;
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        border-radius: 5rem;
    }
    .tci-part-captext{
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        color: #ffffff;
        padding: 2.3125rem;
        padding-top: 0;
    }
    .tci-part-captext a{
        text-decoration: none;
        color: #ffffff;
    }
    .tci-part-captext h3{
        margin: 0;
        font-family: poppins;
        font-size: 1.5rem;
        font-weight: 700;
        transition: 0.2s;
    }
    .tci-part-captext p{
        margin: 0;
        font-family: poppins;
        font-size: 1rem;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .tcp-more{
        display: flex;
        height: 100%;
        width: 100%;
        align-items: flex-end;
        padding-left: 2rem;
    }
    .tcp-more a{
        text-decoration: underline;
        color: #575757;
        font-size: 1rem;
        font-weight: 600;
    }
    .tab-footer{
        margin-top: 1.6875rem;
        padding-top: 2rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .tab-footer a{
        text-decoration: underline;
        color: #575757;
        font-size: 1rem;
        font-weight: 600;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding-left: 5%;
            padding-right: 5%;
        }
        .tci-part{
            height: 22rem;
        }
    }
    @media only screen and (max-width: 1250px){
        section{
            padding-top: 2.5rem;
        }
        .tab-body{
            min-height: 19rem;
        }
        .tci-part{
            height: 19rem;
        }
        .tcp-img img{
            min-height: 19rem;
        }
        .tci-part-captext h3{
            font-size: 1.2rem;
        }
    }
    @media only screen and (max-width: 1050px){
        section h2{
            font-size: 2rem;
        }
        .tab-body{
            min-height: 17rem;
        }
        .tci-part{
            height: 17rem;
        }
        .tcp-img img{
            min-height: 17rem;
        }
        .tci-part-captext h3{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    @media only screen and (max-width: 850px){
        .tc-inner{
            overflow: auto;
            overflow-y: hidden;
            flex-wrap: nowrap;
        }
        .tab-body{
            min-height: 15rem;
        }
        .tcp-img img{
            min-height: 15rem;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding-left: 0;
            padding-right: 0;
            min-height: unset;
        }
        /* section h2{
            font-size: 1.5rem;
        } */
        section p{
            text-align: left;
            font-size: 1rem;
        }
        .section-header{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .tab-head{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .tc-inner{
            padding-left: 1.5%;
            padding-right: 1.5%;
        }
        .tci-part{
            height: 15rem;
        }
    }
</style>