<template>
  <!-- <div class="about">
    <MainNavbar /> -->
    <div class="content">
      <AboutHeader />
      <AboutPassion />
      <AboutTeam />
      <!-- <AboutJoin /> -->
    </div>
    <!-- <MainFooter />
  </div> -->
</template>

<script>
// @ is an alias to /src
// import MainNavbar from '@/components/MainNavbar.vue'
import AboutHeader from '@/components/about/AboutHeader.vue'
import AboutPassion from '@/components/about/AboutPassion.vue'
import AboutTeam from '@/components/about/AboutTeam.vue'
// import AboutJoin from '@/components/about/AboutJoin.vue'

// import MainFooter from '@/components/MainFooter.vue'

export default {
  name: 'AboutPage',
  components: {
    // MainNavbar,
    AboutHeader,
    AboutPassion,
    AboutTeam
    // AboutJoin,
    // MainFooter
  }
}
</script>




