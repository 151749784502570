<template>
    <section>
        <div class="section-header">
            <div class="sh-left">
                <div class="strip"></div>
                <h2>Food Traveller Blogs</h2>
            </div>
            <div class="sh-right">
                <router-link to="/blog" >More articles</router-link>
            </div>
        </div>
        <!-- {{ homeBs1 }} -->
        <div class="section-content">
            <HomeBlogSkeleton v-if="!homeBs1" />
            <div class="sc-inner" v-else>
                <div class="sci-list">
                    <div class="article-list" v-for="article in homeBs1" :key="article.id">
                        <div class="al-image">
                            <img :src="imgsrc + article.picture" alt="food traveller">
                        </div>
                        <div class="al-detail">
                            <div class="al-lable">
                                <span>{{ article.category_name }}</span>
                            </div>
                            <div class="al-title">
                                <router-link :to="'/blog/detail/' + article.slug">
                                    <h3>{{ article.title }}</h3>
                                </router-link>
                            </div>
                            <div class="al-more">
                                <router-link :to="'/blog/detail/' + article.slug" >Readmore</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="article-list al-bottom" v-for="article in homeBs2" :key="article.id">
                        <div class="al-image">
                            <img :src="imgsrc + article.picture" alt="food traveller">
                        </div>
                        <div class="al-detail">
                            <div class="al-lable">
                                <span>{{ article.category_name }}</span>
                            </div>
                            <div class="al-title">
                                <router-link :to="'/blog/detail/' + article.slug">
                                    <h3>{{ article.title }}</h3>
                                </router-link>
                            </div>
                            <div class="al-more">
                                <router-link :to="'/blog/detail/' + article.slug" >Readmore</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sci-main" v-for="article in homeBs3" :key="article.id">
                    <div class="tcp-img">
                        <img v-bind:src="imgsrc + article.picture" alt="food traveller" />
                        <div class="sci-gradient"></div>
                    </div>
                    <div class="sm-caption">
                        <div class="sm-lable">
                            <span>{{ article.category_name }}</span>
                        </div>
                        <div class="sm-title">
                            <router-link :to="'/blog/detail/' + article.slug">
                                <h3>{{ article.title }}</h3>
                            </router-link>
                        </div>
                        <div class="sm-more">
                            <router-link :to="'/blog/detail/' + article.slug" >Readmore</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { computed } from "@vue/runtime-core";
    import { useArticleStore } from "../../stores/article";
    import HomeBlogSkeleton from "../skeletons/HomeBlogSkeleton.vue";
    const homeBs1 = computed(() => useArticleStore().homeBs1);
    const homeBs2 = computed(() => useArticleStore().homeBs2);
    const homeBs3 = computed(() => useArticleStore().homeBs3);
    useArticleStore().setHomeBlog();
    const imgsrc = process.env.VUE_APP_ROOT_API + 'images/articles/';
</script>

<style scoped>
    section{
        width: 100%;
        min-height: 15rem;
        padding-top: 2.5rem;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        padding-bottom: 2.5rem;
        color: #1B4674;
        transition: 0.2s;
    }
    section h2{
        font-family: poppins;
        font-size: 2.625rem;
        font-weight: 700;
        margin: 0;
        margin-top: 1rem;
        line-height: 1;
        transition: 0.2s;
    }
    section p{
        margin-top: 1.6875rem;
        font-size: 1.5rem;
    }
    .small-title{
        color: #75C6EF;
        font-size: 1.125rem;
        font-weight: 600;
    }
    .section-header{
        display: flex;
    }
    .sh-left{
        width: 50%;
        transition: 0.2s;
    }
    .strip{
        width: 10%;
        height: 0.5rem;
        background: #CD4051;
    }
    .sh-right{
        width: 50%;
        text-align: right;
        padding-top: 2.5rem;
        transition: 0.2s;
    }
    .sh-right a{
        text-decoration: underline;
        color: #575757;
        font-size: 1rem;
        font-weight: 600;
    }
    .section-content{
        width: 100%;
        /* height: 100%; */
        margin-top: 2.5rem;
        transition: 0.2s;
    }
    .sc-inner{
        display: flex;
        width: 100%;
        /* max-height: 26.5rem; */
        /* border: 1px solid green; */
        transition: 0.2s;
    }
    .sci-list{
        width: 50%;
        height: 100%;
        height: 100%;
        padding-right: 1rem;
        transition: 0.2s;
    }
    .article-list{
        width: auto;
        height: 12rem;
        display: flex;
        flex-wrap: wrap;
        transition: 0.2s;
    }
    .al-bottom{
        margin-top: 2rem;
    }
    .al-image{
        width: 31%;
        height: 100%;
        transition: 0.2s;
    }
    .al-image img{
        width: 100%;
        height: 100%;
        max-height: 100%;
        border-radius: 10px;
        transition: 0.2s;
    }
    .al-detail{
        position: relative;
        width: 69%;
        padding-left: 2rem;
        padding-right: 2.5rem;
        transition: 0.2s;
    }
    .al-lable{
        padding-top: 0.5rem;
        white-space: nowrap;
        /* border: 1px solid green; */
        /* overflow: hidden; */
        text-overflow: ellipsis;
        /* min-height: 3.6rem; */
        /* max-width: 100px; */
        transition: 0.2s;
    }
    .al-lable span{
        background: #75C6EF;
        color: #ffffff;
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        border-radius: 5rem;
        font-size: 0.9rem;
        /* white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; */
    }
    .al-title{
        position: absolute;
        left: 2rem;
        bottom: 1rem;
        width: 90%;
        transition: 0.2s;
    }
    .al-title a{
        text-decoration: none;
        color: #1B4674;
    }
    .al-title h3{
        font-family: poppins;
        font-size: 2rem;
        font-weight: 700;
        margin-top: 1rem;
        margin-bottom: 1.5rem;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: 0.2s;
    }
    .al-more{
        position: absolute;
        left: 2rem;
        bottom: 0;
        transition: 0.2s;
    }
    .al-more a{
        color: #75C6EF;
        text-decoration: underline;
    }
    .sci-main{
        position: relative;
        width: 50%;
        overflow: hidden;
        transition: 0.2s;
    }
    .tcp-img{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .tcp-img img{
        width: 100%;
        /* min-height: 24rem; */
        height: 100%;
        max-height: 26.5rem;
        border-radius: 10px;
    }
    .sci-gradient{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 45%;
        background-image: linear-gradient(transparent, #000000);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    /* .sci-main img{
        width: 100%;
        height: 100%;
        border-radius: 10px;
    } */
    .sm-caption{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .sm-lable{
        position: absolute;
        top: 2rem;
        left: 2rem;
    }
    .sm-lable span{
        background: #75C6EF;
        color: #ffffff;
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        border-radius: 5rem;
        font-size: 0.9rem;
    }
    .sm-title{
        position: absolute;
        width: 75%;
        left: 2rem;
        bottom: 2.3rem;
        color: #ffffff;
        font-family: poppins;
        font-size: 2rem;
        font-weight: 700;
        transition: 0.2s;
    }
    .sm-title a{
        text-decoration: none;
        color: #ffffff;
    }
    .sm-title h3{
        margin: 0;
    }
    .sm-more{
        position: absolute;
        left: 2rem;
        bottom: 1rem;
        transition: 0.2s;
    }
    .sm-more a{
        color: #ffffff;
        text-decoration: underline;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding-left: 5%;
            padding-right: 5%;
        }
    }
    @media only screen and (max-width: 1250px){
        .al-image{
            width: 40%;
        }
        .al-detail{
            width: 60%;
        }
    }
    @media only screen and (max-width: 1050px){
        section h2{
            font-size: 2rem;
        }
        .section-content{
            margin-top: 2.6rem;
        }
        .sc-inner{
            max-height: 22rem;
        }
        .article-list{
            height: 10rem;
        }
        .al-title h3{
            font-size: 1.2rem;
        }
        .sm-title{
            left: 1rem;
            width: 90%;
            font-size: 1.5rem;
        }
        /* .sm-title{
        } */
        .sm-more{
            left: 1rem;
        }
    }
    @media only screen and (max-width: 850px){
        .sci-list{
            width: 100%;
        }
        .sci-main{
            width: 0;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding-top: 0;
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        /* .section-header{
            display: inline;
        } */
        /* section h2{
            font-size: 1.5rem;
        } */
        .sh-left{
            width: 100%;
        }
        .sh-right{
            width: 0;
            height: 0;
            overflow: hidden;
        }
        .al-image{
            width: 50%;
        }
        .al-detail{
            width: 50%;
            padding-left: 1rem;
        }
        .al-lable span{
            font-size: 0.75rem;
        }
        .al-title{
            left: 1rem;
            bottom: 0.5rem;
        }
        .al-more{
            left: 1rem;
            bottom: 0.5rem;
        }
    }
    @media only screen and (max-width: 390px){
        .sc-inner{
            max-height: unset;
        }
        .sci-list{
            padding-right: 0;
        }
        .article-list{
            height: auto;
            border: 1px solid whitesmoke;
            border-radius: 10px 10px 0 0;
        }
        .al-image{
            width: 100%;
        }
        .al-image img{
            border-radius: 10px 10px 0 0;
        }
        .al-detail{
            width: 100%;
            min-height: 10rem;
            padding-top: 1rem;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
        .al-lable{
            padding-top: 1rem;
        }
        .al-title{
            /* left: 0.5rem; */
            bottom: 0.5rem;
        }
        .al-more{
            /* left: 0.5rem; */
            bottom: 0.5rem;
        }
    }
    /*  */
</style>