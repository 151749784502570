<template>
  <!-- <div class="app">
    <MainNavbar /> -->
    <div class="content">
      <ResepHeader />
      <ResepResep />
    </div>
    <!-- <MainFooter />
  </div> -->
</template>

<script>
// @ is an alias to /src
// import MainNavbar from '@/components/MainNavbar.vue'
import ResepHeader from '@/components/resep/ResepHeader.vue'
import ResepResep from '@/components/resep/ResepResep.vue'

// import MainFooter from '@/components/MainFooter.vue'

export default {
  name: 'ResepPage',
  components: {
    // MainNavbar,
    ResepHeader,
    ResepResep
    // MainFooter
  }
}
</script>