<template>
    <div style="text-align: center; padding-top: 10rem;">
        <h1>Halaman Tidak Ditemukan</h1>
        <h2>404</h2>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>