<template>
    <section>
        <div class="section-bg"></div>
            <div class="section-content">
                <ResepDetailSkeleton  v-if="!resepDetail" />
                <div class="sc-inner" v-else>
                    <div class="sci-left" v-if="resepDetail[0]">
                        <!-- {{ resepDetail[0] }} -->
                        <div class="sl-head">
                            <p class="small-title">{{ resepDetail[0].category_name }}</p>
                            <h1 class="main-title">{{ resepDetail[0].title }}</h1>
                            <p class="p-date">Food traveller, {{ epochDMY(Date.parse(resepDetail[0].created_at)) }}</p>
                        </div>
                        <div class="sl-body">
                            <div class="sl-img">
                                <img :src="imgsrc + resepDetail[0].picture" alt="food traveller" />
                            </div>
                            <div class="sl-content">
                                <div v-html="resepDetail[0].description"></div>
                            </div>
                            <div class="sl-content">
                                <div v-html="resepDetail[0].how_to"></div>
                            </div>
                        </div>
                        <div class="sc-share">
                            <h2>Share to</h2>
                            <div class="sc-share-inner">
                                <div class="scs-part">
                                    <a href="https://www.facebook.com" target="_blank">
                                        <img src="../../assets/images/icons/cc-facebook.png" alt="food traveller">
                                    </a>
                                </div>
                                <div class="scs-part">
                                    <a href="https://www.twitter.com" target="_blank">
                                        <img src="../../assets/images/icons/cc-twitter.png" alt="food traveller">
                                    </a>
                                </div>
                                <div class="scs-part">
                                    <a href="https://www.linkedin.com" target="_blank">
                                        <img src="../../assets/images/icons/cc-linkedin.png" alt="food traveller">
                                    </a>
                                </div>
                                <div class="scs-part">
                                    <a href="https://www.instagram.com" target="_blank">
                                        <img src="../../assets/images/icons/cc-instagram.png" alt="food traveller">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sci-right">
                        <div class="sr-check">
                            <h2>Healthy Check</h2>
                            <div class="hc-list">
                                <div class="hcl-icon">
                                    <img src="../../assets/images/icons/check-sky.png" alt="food traveller">
                                </div>
                                <div class="hcl-text">
                                    <span>Protein</span>
                                </div>
                            </div>
                            <div class="hc-list">
                                <div class="hcl-icon">
                                    <img src="../../assets/images/icons/check-sky.png" alt="food traveller">
                                </div>
                                <div class="hcl-text">
                                    <span>Fats</span>
                                </div>
                            </div>
                            <div class="hc-list">
                                <div class="hcl-icon">
                                    <img src="../../assets/images/icons/check-sky.png" alt="food traveller">
                                </div>
                                <div class="hcl-text">
                                    <span>Good for Healthy</span>
                                </div>
                            </div>
                            <div class="hc-list">
                                <div class="hcl-icon">
                                    <img src="../../assets/images/icons/check-sky.png" alt="food traveller">
                                </div>
                                <div class="hcl-text">
                                    <span>Good for Healthy</span>
                                </div>
                            </div>
                            <div class="hc-list">
                                <div class="hcl-icon">
                                    <img src="../../assets/images/icons/times-red.png" alt="food traveller">
                                </div>
                                <div class="hcl-text">
                                    <span>Grade Oil</span>
                                </div>
                            </div>
                            <div class="hc-list">
                                <div class="hcl-icon">
                                    <img src="../../assets/images/icons/times-red.png" alt="food traveller">
                                </div>
                                <div class="hcl-text">
                                    <span>Processed Meat</span>
                                </div>
                            </div>
                        </div>
                        <div class="sr-part sr-categories">
                            <h2>Categories</h2>
                            <div class="sr-link">
                                <router-link to="/blog/detail">
                                    <h3>Travel Blog</h3>
                                </router-link>
                            </div>
                            <div class="sr-link">
                                <router-link to="/blog/detail">
                                    <h3>Corporate</h3>
                                </router-link>
                            </div>
                            <div class="sr-link">
                                <router-link to="/blog/detail">
                                    <h3>Resep Makanan</h3>
                                </router-link>
                            </div>
                            <div class="sr-link">
                                <router-link to="/blog/detail">
                                    <h3>Bandung Trending</h3>
                                </router-link>
                            </div>
                            <div class="sr-link">
                                <router-link to="/blog/detail">
                                    <h3>Hotel Murah</h3>
                                </router-link>
                            </div>
                        </div>
                        <div class="sr-part sr-tag">
                            <h2>Tags</h2>
                            <div class="sr-tag-inner">
                                <router-link to="/blog/detail">Bandung</router-link>
                                <router-link to="/blog/detail">Culinary Night</router-link>
                                <router-link to="/blog/detail">2022</router-link>
                                <router-link to="/blog/detail">Hotel Murah</router-link>
                                <router-link to="/blog/detail">Culinary Night</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </section>
</template>

<script setup>
    import { useRoute } from 'vue-router'
    import { computed } from "@vue/runtime-core";
    import { useRecipeStore } from "../../stores/recipe";
import ResepDetailSkeleton from '../skeletons/ResepDetailSkeleton.vue';
    const resepDetail = computed(() => useRecipeStore().resepDetail);
    useRecipeStore().setResepDetail(useRoute().params.id);
    function epochDMY(ts){
        const months = ["Januari", "Pebruari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
        var dt = new Date(ts);
        var d = dt.getDay();
        var m = months[dt.getMonth()];
        var y = dt.getFullYear();
        return d + ' ' + m +' ' + y;
    }
    const imgsrc = process.env.VUE_APP_ROOT_API + 'images/resep/';
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
</script>

<style scoped>
    section{
        position: relative;
        width: 100%;
        min-height: 15rem;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        color: #1B4674;
        transition: 0.2s;
    }
    .section-bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 36rem;
        background: #FEF8F0;
        z-index: -1;
        transition: 0.2s;
    }
    .small-title{
        color: #75C6EF;
        font-weight: 600;
        font-size: 1.125rem;
    }
    .main-title{
        font-family: poppins;
        font-size: 3.25rem;
        font-weight: 700;
        margin-top: 1rem;
        margin-bottom: 1rem;
        transition: 0.2s;
        line-height: 1.2;
    }
    .p-date{
        margin: 0;
        margin-top: 1.6875rem;
        font-size: 1rem;
        font-weight: 400;
        width: 31.9375rem;
        transition: 0.2s;
    }
    .section-content{
        width: 100%;
        height: 100%;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        transition: 0.2s;
    }
    .sc-inner{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        padding-top: 2rem;
        transition: 0.2s;
    }
    .sci-left{
        width: 60%;
        transition: 0.2s;
    }
    .sl-body{
        padding-top: 2rem;
    }
    .sl-img{
        margin-bottom: 2rem;
    }
    .sl-img img{
        width: auto;
        width: 100%;
        transition: 0.2s;
    }
    .sl-content{
        text-align: justify;
    }
    .slc-part{
        margin-bottom: 2rem;
    }
    .slc-part h2{
        font-family: poppins;
        font-size: 1.75rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }
    .slc-part ul{
        list-style-type: none;
        padding-left: 0;
    }
    .slc-part ol{
        padding-left: 1rem;
    }
    .slc-part li{
        font-size: 1rem;
    }
    .sc-share{
        margin-top: 2rem;
    }
    .sc-share h2{
        font-family: poppins;
        font-size: 1rem;
        font-weight: 700;
    }
    .sc-share-inner{
        display: flex;
        flex-wrap: nowrap;
        padding-top: 1rem;
    }
    .scs-part{
        margin-right: 1rem;
    }
    .sci-right{
        width: 40%;
        padding-top: 15rem;
        padding-left: 5rem;
        transition: 0.2s;
    }
    .sr-check{
        background: #ffffff;
        min-height: 10rem;
        width: 100%;
        padding: 2rem;
        margin-bottom: 2rem;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.12), 0 6px 20px 0 rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        transition: 0.2s;
    }
    .sr-check h2{
        font-family: poppins;
        font-size: 1.25rem;
        font-weight: 700;
        color: #1B4674;
        margin-bottom: 1rem;
    }
    .hc-list{
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: 0.5rem;
    }
    .hcl-icon{
        width: 7.5%;
        max-width: 22px;
    }
    .hcl-icon img{
        width: 92.5%;
        max-width: 15px;
    }
    .hcl-text{
        width: 85%;
        padding-left: 1rem;
    }
    .sr-part h2{
        font-family: poppins;
        font-weight: 700;
        font-size: 1.25rem;
        color: #bbbbbb;
    }
    .sr-categories{
        width: 100%;
        transition: 0.2s;
        margin-bottom: 2rem;
    }
    .sr-link{
        margin-top: 1rem;
    }
    .sr-link a{
        color: #1B4674;
        text-decoration: none;
    }
    .sr-tag{
        width: 100%;
        transition: 0.2s;
        margin-bottom: 2rem;
    }
    .sr-tag-inner{
        display: flex;
        flex-wrap: wrap;
        padding-top: 1rem;
        width: 100%;
    }
    .sr-tag-inner a{
        font-size: 1rem;
        color: #575757;
        border: 1px solid #575757;
        border-radius: 50px;
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        text-decoration: none;
        margin-top: 1rem;
        margin-right: 1rem;
    }
    @media only screen and (max-width: 1500px){
        .section-bg{
            height: 31rem;
        }
        .section-content{
            padding-left: 5%;
            padding-right: 5%;
        }
        .main-title{
            font-size: 2.6rem;
        }
        .p-date{
            margin-top: 1rem;
        }
    }
    @media only screen and (max-width: 1050px){
        .sc-inner{
            padding-top: 0;
        }
        .sci-left{
            width: 100%;
        }
        .sl-img img{
            width: 100%;
        }
        .main-title{
            font-size: 2rem;
        }
        .p-date{
            width: auto;
        }
        .sci-right{
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding-top: 2rem;
            padding-left: 0;
        }
        .sr-check{
            margin-bottom: 1.5rem;
        }
        .sr-categories{
            width: 50%;
            margin-bottom: 0;
        }
        .sr-tag{
            width: 50%;
            margin-bottom: 0;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding-top: 1rem;
        }
        .section-bg{
            height: 19rem;
        }
        .section-content{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .sci-right{
            padding-top: 1rem;
        }
        .sr-check{
            padding: 0;
            margin-bottom: 1rem;
            box-shadow: unset;
            border-radius: 0;
        }
        .sr-categories{
            width: 100%;
        }
        .sr-tag{
            width: 100%;
        }
    }
</style>