import { defineStore } from "pinia";
import axiosClient from "../axios.js";

export const useIngredientStore = defineStore("ingredient", {
  state: () => ({
    // ==================== state for kamus rempah page
    rempahCategories: null,
    igRempah: null,
    igBahanBaku: null,
    // ==================== state for kamus rempah detail page
    kamusRempahDetail: null,
    // ==================== unused
    articles: null,
    articleNum: 1,
    articleDetail: null,
    articleDetailTitle: 'Article Detail',
    articleCategories: null
  }),
  getters: {
    articlesTppTop: (state) => {
      return state.articlesTpp.slice(0, 2);
    },
  },
  actions: {
    // ==================== actions for kamus rempah page
    async setCategories(){
      const response = await axiosClient.get(`ingredient/kategori/get-all`);
      this.rempahCategories = response.data;
    },
    async setKr(cg, limit){
      const response = await axiosClient.get(`ingredient/kategori/`+ cg +`/` + limit);
      this.igRempah = response.data;
    },
    async setKrbb(cg, limit){
      const response = await axiosClient.get(`ingredient/kategori/`+ cg +`/` + limit);
      this.igBahanBaku = response.data;
    },
    // ==================== actions for kamus rempah detail page
    async setKrDetail(id){
      this.kamusRempahDetail = null;
      const response = await axiosClient.get(`ingredient/detail/`+ id);
      this.kamusRempahDetail = response.data;
    },
    // =======================================
    setArticleNum(nm){
      this.articleNum = nm
    },
    async fetchArticles(limit) {
      const response = await axiosClient.get(`artikel/get-all/` + limit);
      this.articles = response.data;
    },
    async fetchArticleDetail(id) {
      this.articleDetail = false;
      const response = await axiosClient.get(`artikel/detail/` + id);
      this.articleDetail = response.data;
      // this.articleDetailTitle = response.data[0].title;
    },
    async fetchArticleCategories() {
      const response = await axiosClient.get(`artikel/get-categories`);
      this.articleCategories = response.data;
    },
    async fetchArticlesMix(limit) {
      const response = await axiosClient.get(`artikel/get-all/` + limit);
      this.articlesMix = response.data;
    },
    async fetchArticleBtBig(limit) {
      const response = await axiosClient.get(`artikel/get-all/` + limit);
      this.articleBtBig = response.data;
    },
    async fetchArticleBtRight(limit) {
      const response = await axiosClient.get(`artikel/get-all/` + limit);
      this.articleBtRight = response.data;
    },
    //===============
  }
});